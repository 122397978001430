import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import { cloneElement, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import grey from '@@src/styles/colors/grey';
import { getProgress } from '@@stores/ProgressStore';
import { clearRecommendation } from '@@stores/RecommendationStore';
import { onLinkKeyListener } from '@@utils/helpers';

import OnDemand from '../../@types/OnDemand';
import PlayOverlay from '../../decorators/PlayOverlay';
import { OdLink } from '../../routes';
import fontFamily from '../../styles/typography/fontFamily';
import OdFavouriteButton from '../Buttons/OdFavouriteButton';
import OdRemoveFromContinueWatchingButton from '../Buttons/OdRemoveFromContinueWatchingButton';
import { transitionDelay, transitionDuration } from '../Carousel/TileSlider';
import Image, { ImageProps } from '../Html/Image';
import VideoProgressBar from '../ProgressBar/VideoProgressBar';
import Metadata from './Metadata';

interface TileClasses {
  card?: any;
  root?: string;
  extraMetadata?: string;
  classification?: string;
  ctaButton?: string;
}

export type TileProps<ItemType = OnDemand.Video | OnDemand.TvSeries | OnDemand.Collection | OnDemand.Page> =
  OnDemand.Tile<ItemType>
  & {
    link?: boolean;
    onFocus?: () => void;
    classes?: TileClasses;
    onClick?(e): void;
    ctaButton?: any;
    showProgress?: boolean;
    /* show or hide cta buttons & meta data, needs to be controlled from the parent because only 1 tile can be active per shelf */
    isActive?: boolean;
    imageProps: ImageProps;
  };

export const useTileStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    card: {
      borderRadius: 0,
      position: 'relative',
      width: '100%',
      top: 0,
      left: 0,
    },
    active: {},
    cardContent: {
      padding: '10px',
      borderRadius: 4,
      [theme.breakpoints.down('xs')]: {
        padding: '8px',
      },
    },
    tileTitle: {
      overflow: 'hidden',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
      lineHeight: 1.2,
      '& + div': {
        marginTop: 4,
      },
      fontFamily: fontFamily.roboto,
    },
    tileLink: {
      display: 'block',
    },
    cardActionArea: {},
    playOverlay: {
      position: 'absolute',
      paddingBottom: 58,
    },
    ctaButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      padding: 0,
      opacity: 0,
      transition: `opacity ${transitionDuration}`,
      transitionDelay,
      zIndex: 1,
      '$active &': {
        opacity: 1,
      },
      [theme.breakpoints.down('xl')]: {
        fontSize: 40,
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: 36.9231,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 33.8462,
      },
    },
    imageContainer: {
      borderRadius: 4,
      '&.zoomable': {
        overflow: 'hidden',
      },
      '&.zoomable img': {
        transition: 'all .25s',
      },
      '&.zoomable:hover img': {
        transform: 'scale(1.07)',
      },
      backgroundColor: grey.blackpearl,
    },
    // force image to render in 16:9 aspect ratio if it comes back from the resizer in a different aspect ratio because the source image is smaller than the target resolution
    // NOTE: this can be removed if the resizer can always return the target resolution regardless of the source
    'imageContainer-16:9': {
      overflow: 'hidden',
      height: 0,
      position: 'relative',
      paddingTop: '56.25%',
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    'imageContainer-2:3': {
      overflow: 'hidden',
      height: 0,
      position: 'relative',
      paddingTop: '150%',
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
  });
});

const Tile: FunctionComponent<TileProps> = (props) => {
  const {
    item,
    metadata,
    extraMetadata,
    onClick = () => {
      // do nothing
    },
    ctaButton = null,
    showProgress = false,
    link = true,
    imageProps,
    isActive = false,
  } = props;

  const classes = useTileStyles(props);
  const imageHeightProp = get(item, 'size.height');

  // set the image height so that the it's not at 0 height when the image is being loaded or if the image failed to load
  const [imageHeight, setImageHeight] = useState<'auto' | number>('auto');
  useEffect(() => {
    if (imageHeightProp) {
      setImageHeight(imageHeightProp);
    }
  }, [imageHeightProp]);

  const imgTag = (
    <div
      style={{ height: imageHeight }}
      className={clsx(classes.imageContainer, classes[`imageContainer-${imageProps.ratio}`])}
    >
      <Image
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...imageProps}
        onLoad={() => {
          // because the image height was approximate, we'll set it back to auto after the image loaded
          setImageHeight('auto');
        }}
        imageSizes={{ all: 300 }}
      />
      {showProgress && (
        <VideoProgressBar videoId={item.id} position="bottom" transparent/>
      )}
    </div>
  );

  const handleClick = useCallback((e) => {
    onClick(e);
  }, [onClick]);

  let content = (
    <div className={classes.cardActionArea}>
      {imgTag}
      <div className={classes.cardContent} data-testid="tile-chin">
        <Typography variant="body1" component="h3" className={classes.tileTitle}>
          {item.title}
        </Typography>
        {
          metadata ? (
            <Metadata
              metadata={metadata}
              isActive={isActive}
              extraMetadata={extraMetadata}
              classification={'classification' in item ? item.classification : null}
            />
          ) : <div>&nbsp</div>
        }
      </div>
    </div>
  );

  if (link) {
    content = (
      <OdLink
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...item.route}
        tabIndex={isActive ? 0 : -1}
        onClick={handleClick}
        onKeyPress={onLinkKeyListener(handleClick)}
        aria-label={item.title}
        className={classes.tileLink}
        onContextMenu={(e) => {
          // prevent context menu on mobile, so we can show cta button on long press
          if (isMobile) {
            e.preventDefault();
          }
        }}
      >
        {content}
      </OdLink>
    );
  }

  return (
    <div className={clsx(classes.root, isActive ? classes.active : '')} data-testid="tile">
      <div className={classes.card} aria-hidden={!isActive}>
        {content}
        <div>
          {
            ctaButton && cloneElement(ctaButton, {
              classes: {
                ...get(ctaButton, 'props.classes', {}),
                root: clsx(get(ctaButton, 'props.classes.root', ''), classes.ctaButton),
              },
              tabIndex: isActive ? 0 : -1,
            })
          }
        </div>
      </div>
    </div>
  );
};

export default Tile;

interface TileWithFavouriteButtonProps<T = OnDemand.Video | OnDemand.TvSeries> extends TileProps<T> {
  shelfLocation: string;
}

export const TileWithFavouriteButton: FunctionComponent<TileWithFavouriteButtonProps> = (props) => {
  const { item, shelfLocation } = props;

  const ctaButton = <OdFavouriteButton item={item} location={shelfLocation}/>;
  return (
    <Tile {...props} ctaButton={ctaButton}/>
  );
};

type ContinueWatchingTileProps = TileProps & {
  onRemoveFromContinueWatching?(e, item: Partial<OnDemand.Video | OnDemand.TvSeries>): void;
};

const usePlayOverlayStyles = makeStyles(() => {
  return {
    playOverlay: {
      'html:not(.mobile) &:hover $playButton': {
        opacity: 0.8,
      },
      '&:focus $playButton': {
        opacity: 1,
      },
    },
    playButton: {
      opacity: 0,
      marginBottom: '3.6rem',
      transition: `opacity ${transitionDuration}`,
      transitionDelay,
    },
    ctaButton: {},
  };
});

export const ContinueWatchingTile: FunctionComponent<ContinueWatchingTileProps> = (props) => {
  const {
    onRemoveFromContinueWatching = () => {
      // do nothing
    },
    ...tileProps
  } = props;

  const { item, onClick } = tileProps;
  const dispatch = useAppDispatch();
  const classes = usePlayOverlayStyles(props);
  const { t } = useTranslation('common');
  const progressData = useAppSelector((state) => {
    if (item.type === 'Movie'
      || item.type === 'Episode'
      || item.type === 'Clip'
      || item.type === 'OneOff'
    ) {
      return getProgress(state, item.id);
    }

    return null;
  });

  if (item.type === 'Movie'
    || item.type === 'Episode'
    || item.type === 'Clip'
    || item.type === 'OneOff'
  ) {
    const handleCtaClick = (e) => {
      onRemoveFromContinueWatching(e, item);
    };
    const ctaButton = <OdRemoveFromContinueWatchingButton item={item} onClick={handleCtaClick}/>;

    let progress = 0;
    if (progressData) {
      progress = progressData.completed ? 0 : progressData.seconds;
    }

    // we use progress data here to override the play overlay label
    // the metadata in the chin comes straight from api
    let label;
    if (progress > 0) {
      if (item.type === 'Episode') {
        label = `${t('playback.resumeEpisode', {
          title: item.title,
          season: item.episodeData.seasonNumber,
          episode: item.episodeData.episodeNumber,
          time: progress,
          episodeTitle: '',
        })}.`;
      } else {
        label = `${t('playback.resumeVideo', { title: item.title, time: progress })}.`;
      }
    } else if (item.type === 'Episode') {
      label = `${t('playback.playEpisode', {
        title: item.title,
        season: item.episodeData.seasonNumber,
        episode: item.episodeData.episodeNumber,
      })}.`;
    } else {
      label = `${t('playback.playVideo', { title: item.title })}.`;
    }

    const classification = get(item, 'classification');
    if (classification) {
      label += ` ${t('metadata.extendedClassification', {
        code: classification,
        description: t(`classification.${classification}`),
      })}`;
    }

    const handlePlayClick = (e) => {
      dispatch(clearRecommendation());

      if (onClick) {
        onClick(e);
      }
    };

    return (
      <PlayOverlay
        tabIndex={tileProps.isActive ? 0 : -1}
        video={item}
        classes={{ root: classes.playOverlay, playButton: classes.playButton }}
        label={label}
        onClick={handlePlayClick}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Tile {...tileProps} ctaButton={ctaButton} link={false} showProgress/>
      </PlayOverlay>
    );
  }

  // next episode of a program
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <Tile {...tileProps}/>;
};

export const TileWithPlayButton: FunctionComponent<TileWithFavouriteButtonProps<OnDemand.Video>> = (props) => {
  const {
    item, shelfLocation, isActive, onClick,
  } = props;
  const { t } = useTranslation('common');

  const label = `${t('playback.playVideo', { title: item.title })}.`;

  const handleFavButtonClick = useCallback((e) => {
    // to prevent the click propagates to the play button
    e.stopPropagation();
  }, []);

  const ctaButton = item.type !== 'Episode'
    ? <OdFavouriteButton item={item} location={shelfLocation} onClick={handleFavButtonClick}/> : null;
  const classes = usePlayOverlayStyles(props);
  return (
    <PlayOverlay
      tabIndex={isActive ? 0 : -1}
      video={item}
      classes={{ root: classes.playOverlay, playButton: classes.playButton }}
      label={label}
      onClick={onClick}
    >
      <Tile {...props} ctaButton={ctaButton} link={false}/>
    </PlayOverlay>
  );
};

export function getTileComponentByTileItem(item: OnDemand.Tile['item']) {
  if (
    ['Clip', 'Episode'].includes(item.type)
    || (
      'isLiveStream' in item && item.isLiveStream
      && item.available
    )
  ) {
    return TileWithPlayButton;
  }

  if (
    ('isLiveStream' in item && item.isLiveStream)
    || item.type === 'Collection'
    || item.type === 'Page'
  ) {
    return Tile;
  }

  return TileWithFavouriteButton;
}
