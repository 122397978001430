import { ClassNameMap } from '@material-ui/styles';
import { forwardRef, PropsWithChildren, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import OdFavouriteButtonV2 from '@@src/components/Buttons/OdFavouriteButtonV2';
import Metadata from '@@src/components/Tiles/TilesV2/Metadata';
import OnDemand2 from '@@types/OnDemand2';

import BaseTile from './BaseTile';

/* eslint-disable react/require-default-props */
export interface SeriesTileProps {
  collectionTileItem: OnDemand2.CollectionItemSeries;
  displayType: '2:3' | '16:9';
  onFavourited?: (item: OnDemand2.CollectionItemSeries) => void;
  onUnfavourited?: (item: OnDemand2.CollectionItemSeries) => void;
  onActivate?: () => void;
  onDeactivate?: () => void;
  canBeFocused?: boolean;
  onClick?: () => void;
  classes?: ClassNameMap;
}
/* eslint-enable react/require-default-props */

const SeriesTile = forwardRef<HTMLDivElement, PropsWithChildren<SeriesTileProps>>((props, ref: RefObject<HTMLDivElement>) => {
  const {
    collectionTileItem, displayType, onFavourited, onUnfavourited,
    onActivate, onDeactivate, canBeFocused, onClick, classes, ...other
  } = props;

  const { t } = useTranslation('common');

  const favouriteClickHandler = useCallback((e: MouseEvent, hasFavourited: boolean, item: OnDemand2.CollectionItemSeries) => {
    if (hasFavourited && onFavourited) {
      onFavourited(item);
    } else if (!hasFavourited && onUnfavourited) {
      onUnfavourited(item);
    }
  }, [onFavourited, onUnfavourited]);

  const seasonsAvailable = collectionTileItem.seasonCount ? t('metadata.seasonsAvailable', { count: collectionTileItem.seasonCount }) : undefined;

  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      <BaseTile
        classes={classes}
        title={collectionTileItem.title}
        subtitle={collectionTileItem.genre}
        metadata={(
          <Metadata
            list={[
              collectionTileItem.genre,
              collectionTileItem.language,
              seasonsAvailable,
            ]}
          />
        )}
        description={collectionTileItem.description}
        displayType={displayType}
        link={collectionTileItem.route}
        imageId={collectionTileItem.imageId}
        ctaButton={(
          <OdFavouriteButtonV2
            item={collectionTileItem}
            onClick={favouriteClickHandler}
          />
        )}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        canBeFocused={canBeFocused}
        onClick={onClick}
      />
    </div>
  );
});

export default SeriesTile;
