import FavouriteApi from '../apis/FavouritesApi';

export function addVideo(videoId: string): Promise<boolean> {
  return FavouriteApi.add('movie', videoId);
}

export function removeVideo(videoId: string): Promise<boolean> {
  return FavouriteApi.remove('movie', videoId);
}

export function addSeries(seriesId: string): Promise<boolean> {
  return FavouriteApi.add('program', seriesId);
}

export function removeSeries(seriesId: string): Promise<boolean> {
  return FavouriteApi.remove('program', seriesId);
}

interface Favourites {
  videos: string[];
  series: string[];
}

export function list(): Promise<Favourites> {
  return FavouriteApi.list();
}

export function getFavouriteItems(page: number = 1, pageSize: number = 30) {
  return FavouriteApi.fetchFavouriteItems(page, pageSize);
}

export default {
  addVideo,
  removeVideo,
  addSeries,
  removeSeries,
  list,
};
