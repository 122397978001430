import { ClassNameMap } from '@material-ui/styles';
import { forwardRef, PropsWithChildren, RefObject } from 'react';

import OnDemand2 from '@@types/OnDemand2';

import BaseTile from './BaseTile';

/* eslint-disable react/require-default-props */
export interface PageTileProps {
  collectionTileItem: OnDemand2.CollectionItemPage;
  displayType: '2:3' | '16:9';
  onActivate?: () => void;
  onDeactivate?: () => void;
  canBeFocused?: boolean;
  onClick?: () => void;
  classes?: ClassNameMap;
}
/* eslint-enable react/require-default-props */

const PageTile = forwardRef<HTMLDivElement, PropsWithChildren<PageTileProps>>((props, ref: RefObject<HTMLDivElement>) => {
  const {
    collectionTileItem, displayType, onActivate, onDeactivate, onClick, canBeFocused, classes, ...other
  } = props;

  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      <BaseTile
        classes={classes}
        title={collectionTileItem.title}
        description={collectionTileItem.description}
        displayType={displayType}
        link={collectionTileItem.route}
        imageId={collectionTileItem.imageId}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        onClick={onClick}
        canBeFocused={canBeFocused}
      />
    </div>
  );
});

export default PageTile;
