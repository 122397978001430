import * as ls from 'local-storage';
import {
  get, set, has, cloneDeep, toString, toInteger, transform, isEmpty,
} from 'lodash';
import { isMobile } from 'react-device-detect';

import OnDemand2 from '@@src/@types/OnDemand2';
import { PlayerMetadata } from '@@src/pages/WatchPage';
import { BASENAME, VERSION } from '@@utils/constants';

import OnDemand from '../@types/OnDemand';
import i18n, { getLanguageNameByCode, getUrlPrefix, SupportedLanguage } from '../i18n';
import { generateFullUrlFromLinkProps } from '../routes';
import Logger from './logger/Logger';

declare global {
  interface Window {
    digitalData: any;
    adobeDataLayer: any;
  }
}

let userDataUpdatePromiseResolve;
const userDataUpdatePromise = new Promise((resolve) => { userDataUpdatePromiseResolve = resolve; });

// normalise the value according to the rules here https://confluence2.sbs.com.au/pages/viewpage.action?pageId=137822616
function normalise(value: string) {
  // lowercase and convert space to hyphen
  return value.replace(/\s+/g, '-').toLowerCase();
}

interface PageInformation {
  name: string;
  previousName: string;
  language: string;
}

const pageInformation: PageInformation = {
  name: '',
  previousName: '',
  language: '',
};

const defaultDigitalData: any = {
  page: {
    pageInfo: {
      pageName: '',
      pageID: '',
      language: '',
      destinationURL: '',
      referralURL: '',
      originalReferrerURL: '',
      previousPageURL: '',
      previousPageName: '',
      title: '',
      siteName: 'SBS On Demand',
      domain: 'www.sbs.com.au',
      path: '',
      clickSource: {
        clickSourceTitle: '',
        clickSourceType: '',
        clickSourcePlacement: '',
      },
      campaign: {
        trackingCode: '',
      },
    },
    category: {
      primaryCategory: '',
      siteSubSection1: '',
      siteSubSection2: '',
      siteSubSection3: '',
      siteSubSection4: '',
    },
    attributes: {
      content: {
        pageClass: '',
        contentType: '',
        articleType: '',
        contentLanguage: '',
        articleID: '',
        publishedDate: '',
        updatedDate: '',
        newsTopic: '',
        title: '',
        author: [],
        subject: [],
        charter: '',
        theme: [],
        source: [],
        person: [],
        geography: [],
        organisation: [],
        program: [],
        recommendable: '',
      },
      ondemand: {
        season: '',
        programType: [],
        programNextAvailableDate: '',
        expirationDate: '',
        pubDate: '',
        grouping: [],
        countryOfOrigin: [],
        genre: [],
        tone: [],
        era: [],
        yearOfProduction: '',
        consumerAdvice: [],
        classification: [],
        organisation: [],
        segmentNumber: '',
        subject: [],
        awardNomination: '',
        episodeNumber: '',
        episodeTitle: '',
        mainCast: [],
        guestCast: [],
        presenter: [],
        place: [],
        videoName: '',
        mpxId: '',
        channel: [],
        programName: '',
        seasonNumber: '',
        language: [],
        subGenre: [],
        theme: [],
        collection: [],
        keywords: [],
      },
      error: {
        errorPage: '',
        errorMessage: '',
        errorCode: '',
      },
      eventList: [],
    },
  },
  user: {
    userInfo: {
      loggedIn: '',
      userID: '',
      syncUserID: [],
      loggedInVisit: '',
      loggedInType: '',
      userType: '',
      newsletterName: '',
    },
    userSegment: {
      cxenseSegmentIDs: [],
      adobeSegmentIDs: [],
      strategicSegmentIDs: [],
    },
  },
  environment: {
    browser: {
      userAgent: '',
      version: '',
    },
    platform: {
      deviceType: '',
      deviceLanguage: '',
      deviceName: '',
      sourceType: 'web',
      osName: '',
    },
  },
  version: '1.0',
  sbsVersion: {
    variant: 'OD',
    version: VERSION,
  },
};

export interface PlayerEventMetadata {
  chapterData?: {
    current: number;
    total: number;
  } | Record<string, never>;
  subtitleLanguage?: string;
  subtitleLanguageLabel?: string;
  audioTrackSelected?: string;
  audioTrackLabel?: string;
  milestone?: number;
  videoPlayerEvent?: {
    videoAudioTrack?: string;
    videoSubtitle?: string;
  }
}

export interface ODEventData {
  currentChapterData?: {
    current?: number,
    total?: number,
  },
  milestone?: number,
  adMetadata?: AdMetadata,
}

export interface AdobePlayerEventMetadata {
  playerName: string;
  location: string;
  version: string;
  userPreferences: {
    subtitleLanguage?: string;
    subtitleLanguageLabel?: string;
    audioTrackSelected?: string;
    audioTrackLabel?: string;
  }
}

export interface AdobeRecommendationDetails {
  videoInfo: {
    length: string;
    mpxID: string;
    pilatID: string;
    videoName: string;
    videoURL: string;
  }
}

export interface AdMetadata {
  id: string;
  name: string;
  description: string;
  duration: number;
  adPodPosition: number;
  totalAds: number;
  creativeId: string;
  orderId: string;
  podIndex: number;
}

interface UserData {
  userId: string;
  emailHash: string;
  isLoggedIn: boolean;
}

const userInformation: UserData = {
  userId: undefined,
  emailHash: undefined,
  isLoggedIn: false,
};

interface PlayerInfo {
  playerMetadata?: PlayerMetadata;
  playerUserSettings?: PlayerUserSettings;
}

const playerInfo: PlayerInfo = {
  playerMetadata: undefined,
  playerUserSettings: undefined,
};

export interface PlayerUserSettings {
  subtitleLanguage: string;
  subtitleLabel: string;
  audioLanguage: string;
  audioLabel: string;
}

export interface PersonalisationData {
  recommendationId: string;
  recommendationVariantName: string;
}

interface DataLayerType {
  init(): void;
  updatePageLanguage(lang: string): void;
  // this will update the page data, should only be called when the location changed
  updatePageData(): void;
  // this will update few page data that relies on object that may be updated later in the render process, eg: document.title
  partialUpdatePageData(): void;
  resetPageAttribute(): void;
  updatePageAttributesForVideo(video: OnDemand.Video): void;
  updatePageAttributesForSeries(series: OnDemand.TvSeries): void;
  updateUserData(userId: string, emailHash: string): void;
  updatePlayerInfo(playerData: PlayerMetadata, playerUserSettings?: PlayerUserSettings): void;
  setClickSource(clickSourceTitle: string, clickSourceType: string, clickSourceRowNumber: number, clickSourceTileNumber: number): void;
  setPageError(errorCode: string, errorMessage: string): void;
  generateVideoObject(item: OnDemand.Video | OnDemand.TvSeries): DataLayerVideoObject;
  events: {
    pageLoad(title: string, errorCode?: string, errorMessage?: string): void;
    videoTrackingEvent(
      actionName: string,
      videoData: OnDemand.Video,
      additionalAttributes?: Record<string, unknown>,
    ): void;
    videoPlaybackTrackingEvent(actionName: string, eventDetails: Record<string, unknown>): void,
    addToFavourites(item: Partial<OnDemand.Video | OnDemand.TvSeries>, location: string, personalisation?: PersonalisationData): void;
    removeFromFavourites(item: Partial<OnDemand.Video | OnDemand.TvSeries>, location: string, personalisation?: PersonalisationData): void;
    addToFavouritesV2(item: OnDemand2.CollectionItem, location: string, personalisation?: PersonalisationData): void;
    removeFromFavouritesV2(item: OnDemand2.CollectionItem, location: string, personalisation?: PersonalisationData): void;
    queueLanguageChange(
      language: SupportedLanguage,
      reloadUrl: string,
    ): void;
    playNextEpisode(
      action: 'tap' | 'lapse' | 'keepWatching' | 'display' | 'promoCardDisplay' | 'promoCardTap',
      videoData: OnDemand.Video,
      playerData: PlayerMetadata,
      additionalAttributes: Record<string, unknown>,
    ): void;
    videoNavigation(
      actionName: string,
      videoData: OnDemand.Video,
      playerData: PlayerMetadata,
      additionalAttributes: Record<string, unknown>,
    ): void;
    recEndCard(
      action: 'recommendedEndCard_display' | 'recommendedEndCard_tap' | 'recommendedEndCard_info' | 'recommendedEndCard_exit',
      videoData: OnDemand.Video,
      recommendedVideoData: OnDemand.Video,
      playerData: PlayerMetadata,
      additionalAttributes: Record<string, unknown>,
    ): void;
    newsletterPromo(
      interactionName: string,
    ): void;
    liveTvPageClickAction(
      interactionName: 'view-sbs-tv-guide',
    ): void;
    episodePickerViewAll(): void;
    episodePickerSelectSeason(
      seasonName: string,
    ): void;
    episodePickerSelectEpisode(
      episodeName: string,
      videoId: string,
    ): void;
    pipActivated(): void;
    adOnPauseDismiss(
      actionName: string,
      additionalAttributes: Record<string, unknown>,
    ): void;
    clickSignInButton(): void;
    clickCreateAccountButton(): void;
    searchResult(
      type: 'directSearch' | 'suggestedSearch' | 'searchResult',
      term: string,
      keyword?: string,
    ): void;
    sortDropDownDisplay(): void;
    sortDropDownSelect(value: string): void;
    filterModalDisplay(): void;
    filterSortModalDisplay(): void;
    filterModalClearAll(): void
    filterModalShowResult(filters: Record<string, string[]>, totalResult: number): void;
    filterModalSortSubmit(value: string): void;
  };
}

interface DataLayerLSQueueOptions {
  expires: number;
  expectedUrl: string;
}

interface DataLayerLSQueueEntry extends DataLayerLSQueueOptions {
  data: any;
}

interface DataLayerLSQueue {
  events: DataLayerLSQueueEntry[];
}

interface DataLayerVideoObject {
  videoInfo: {
    videoName: string;
    mpxID: string;
    pilatID: string;
    length: string;
    videoURL: string;
  };
  attribute: {
    contentType: string;
    channel: string[];
    programName: string;
    episodeNumber: string;
    seasonNumber: string;
    language: string[];
    countryOfOrigin: string;
    classification: string;
    awardNomination: string;
    genre: string[];
    subGenre: string[];
    theme: string[];
    tone: string[];
    collection: string[];
    keywords: string[];
    subject: string[];
  };
}

interface VideoDetails {
  title: string;
  classification: string;
  collection: string[];
  contentType: string;
  theme: string[];
  tone: string[];
  mediaPart: string;
  channel: string[];
  country: string[];
  genre: string[];
  subgenre: string[];
  language: string[];
  episodeDetails: {
    seriesTitle: string;
    episodeNumber: string;
    seasonNumber: string;
  }
  entityType: string;
  parentEntityType: string;
}

interface AdobeDataLayerMediaObject {
  progress?: string;
  videoPlayerEvent?: {
    videoSubtitle?: 'On' | 'Off';
    videoAudioTrack: 'On';
  },
  mpxId: string;
  pilatId: string;
  mediaType: string;
  entityId: string;
  parentEntityId: string;
  videoDetails: VideoDetails;
}

export function generateAdobeMediaObject(item: Partial<OnDemand.Video | OnDemand.TvSeries>) {
  const mediaObject: AdobeDataLayerMediaObject = {
    mpxId: '',
    pilatId: '',
    mediaType: 'video',
    entityId: '',
    parentEntityId: '',
    videoDetails: {
      title: '',
      classification: '',
      collection: [],
      contentType: '',
      theme: [],
      tone: [],
      mediaPart: '',
      channel: [],
      country: [],
      genre: [],
      subgenre: [],
      language: [],
      entityType: '',
      parentEntityType: '',
      episodeDetails: {
        seriesTitle: '',
        episodeNumber: '',
        seasonNumber: '',
      },
    },
  };

  if (item.type === 'Movie' || item.type === 'OneOff' || item.type === 'Episode' || item.type === 'Clip') {
    mediaObject.entityId = item.catalogueId || '';
    mediaObject.videoDetails.entityType = item.entityType || '';
    mediaObject.videoDetails.title = item.cdpTitle ?? item.title;
    mediaObject.mpxId = item.id;
    mediaObject.pilatId = get(item, 'pilatId', '');

    mediaObject.videoDetails.contentType = item.useType ? `video:${item.useType.toLowerCase()}` : 'Full Episode';
    mediaObject.videoDetails.channel = get(item, 'channels', []);

    if (item.type === 'Episode') {
      mediaObject.parentEntityId = item.episodeData.seriesId;
      mediaObject.videoDetails.parentEntityType = item.episodeData.seriesEntityType;
      mediaObject.videoDetails.episodeDetails.seriesTitle = item.episodeData.programName;
      mediaObject.videoDetails.episodeDetails.episodeNumber = item.episodeData.episodeNumber.toString();
      mediaObject.videoDetails.episodeDetails.seasonNumber = item.episodeData.seasonNumber.toString();
    }

    mediaObject.videoDetails.language = get(item, 'languages', []);
    mediaObject.videoDetails.country = get(item, 'countries', []);
    mediaObject.videoDetails.classification = get(item, 'classification', '');
    mediaObject.videoDetails.genre = get(item, 'genres', []);
    mediaObject.videoDetails.subgenre = item.subgenres ? item.subgenres.map((subGenre) => {
      return subGenre.replace(/^.*?\//, '');
    }) : [];
    mediaObject.videoDetails.theme = get(item, 'themes', []);
    mediaObject.videoDetails.tone = get(item, 'tones', []);
    mediaObject.videoDetails.collection = get(item, 'collections', []);
  } else if (item.type === 'TVSeries') {
    mediaObject.videoDetails.genre = item.genres;
    mediaObject.videoDetails.classification = item.classification;
    mediaObject.videoDetails.episodeDetails.seriesTitle = item.title;

    if (item.featuredVideo) {
      mediaObject.mpxId = item.featuredVideo.video.id;
      mediaObject.videoDetails.title = item.featuredVideo.video.title;
    }
  }

  return mediaObject;
}

export function generateAdobeMediaObjectV2(item: OnDemand2.CollectionItem) {
  const mediaObject: AdobeDataLayerMediaObject = {
    mpxId: '',
    pilatId: '',
    mediaType: 'video',
    entityId: '',
    parentEntityId: '',
    videoDetails: {
      title: '',
      classification: '',
      collection: [],
      contentType: '',
      theme: [],
      tone: [],
      mediaPart: '',
      channel: [],
      country: [],
      genre: [],
      subgenre: [],
      language: [],
      entityType: '',
      parentEntityType: '',
      episodeDetails: {
        seriesTitle: '',
        episodeNumber: '',
        seasonNumber: '',
      },
    },
  };

  if (item.entityType === 'Movie' || item.entityType === 'Program' || item.entityType === 'Episode' || item.entityType === 'Clip') {
    mediaObject.videoDetails.title = item.cdpTitle ?? item.title;
    mediaObject.mpxId = item.mpxMediaId;
    mediaObject.pilatId = get(item, 'pilatId', '');

    mediaObject.videoDetails.contentType = 'Full Episode';
    mediaObject.videoDetails.channel = get(item, 'channels', []);

    if (item.entityType === 'Episode') {
      mediaObject.videoDetails.episodeDetails.seriesTitle = item.seriesTitle;
      mediaObject.videoDetails.episodeDetails.episodeNumber = toString(item.episodeNumber);
      mediaObject.videoDetails.episodeDetails.seasonNumber = toString(item.seasonNumber);
    }

    mediaObject.videoDetails.language = get(item, 'languages', []);
    mediaObject.videoDetails.country = get(item, 'countries', []);
    mediaObject.videoDetails.classification = get(item, 'classification', '');
    mediaObject.videoDetails.genre = get(item, 'genres', []);
    mediaObject.videoDetails.subgenre = [];
    mediaObject.videoDetails.theme = get(item, 'themes', []);
    mediaObject.videoDetails.tone = get(item, 'tones', []);
    mediaObject.videoDetails.collection = get(item, 'collections', []);
  } else if (item.entityType === 'Series') {
    mediaObject.videoDetails.genre = [item.genre];
    mediaObject.videoDetails.classification = item.classificationId;
    mediaObject.videoDetails.episodeDetails.seriesTitle = item.title;
  }

  return mediaObject;
}

export function generateBrowserDetails() {
  return {
    userAgent: navigator.userAgent,
    language: navigator.language,
    platformType: 'web browser',
    platformName: `web ${isMobile ? 'mobile' : 'desktop'}`,
  };
}

interface AdobeClickSource {
  clickSourceRowNumber: string;
  clickSourceTitle: string;
  clickSourceType: string;
  clickSourceTileNumber?: string;
}

const DataLayer = ((): DataLayerType => {
  let digitalData = cloneDeep(defaultDigitalData);
  let clickSource = cloneDeep(defaultDigitalData.page.pageInfo.clickSource);
  let adobeClickSource: AdobeClickSource | undefined;

  function getPageCategories() {
    let path = window.location.pathname;
    const basename = BASENAME;

    // Remove the language code from the beginning of the path
    const urlPrefix = getUrlPrefix(i18n.language);
    if (urlPrefix) {
      const languageRegex = new RegExp(`^${basename}/${urlPrefix}(/.*)?$`);
      path = path.replace(languageRegex, `${basename}$1`);
    }

    const videoRegex = new RegExp(`^(${basename}/video/)[0-9]+/(.*)$`);

    if (videoRegex.exec(path)) {
      path = path.replace(videoRegex, '$1$2');
    }

    // trim '/' and split by '/'
    return path.replace(/^\//, '')
      .replace(/\/$/, '')
      .split('/');
  }

  function setPageCategory() {
    const categories = getPageCategories();
    const { page: { category: ddPageCategory } } = digitalData;
    [
      ddPageCategory.primaryCategory = '',
      ddPageCategory.siteSubSection1 = '',
      ddPageCategory.siteSubSection2 = '',
      ddPageCategory.siteSubSection3 = '',
      ddPageCategory.siteSubSection4 = '',
    ] = categories;
  }

  function getPageNameFromCategories() {
    const categories = getPageCategories();
    let pageName = 's';

    categories.forEach((category) => {
      pageName += `:${category}`;
    });

    return pageName;
  }

  function fromInternalUrl() {
    return !!document.referrer && document.referrer.indexOf(document.location.host) !== -1;
  }

  function getExternalReferrer() {
    if (!fromInternalUrl()) {
      return document.referrer;
    }
    return '';
  }

  function getOriginalReferrerUrl() {
    const value = ls.get('originalReferrerUrl');
    if (value) {
      return value;
    }

    const referrer = getExternalReferrer();
    ls.set('originalReferrerUrl', referrer);

    return referrer;
  }

  function getPageId() {
    const path = document.location.pathname;

    const videoMatch = /video\/([^/]+)/i.exec(path);
    if (videoMatch) {
      return videoMatch[1];
    }

    const programMatch = /program\/([^/]+)/i.exec(path);
    if (programMatch) {
      return programMatch[1];
    }

    return '';
  }

  function getTrackingCode() {
    const params = new URLSearchParams(document.location.search.substring(1));
    return params.get('cid') || '';
  }

  function setPageInfo() {
    const { pageInfo } = digitalData.page;

    pageInfo.previousPageURL = pageInfo.destinationURL;
    pageInfo.previousPageName = pageInfo.pageName;

    pageInfo.pageName = getPageNameFromCategories();
    pageInfo.destinationURL = window.location.href;

    pageInfo.originalReferrerURL = getOriginalReferrerUrl();
    pageInfo.referralURL = getExternalReferrer();

    pageInfo.title = document.title;
    pageInfo.path = document.location.pathname;
    pageInfo.pageID = getPageId();

    pageInfo.campaign.trackingCode = getTrackingCode();

    pageInfo.clickSource = cloneDeep(clickSource);
    // reset the local clickSource
    clickSource = cloneDeep(get(defaultDigitalData, 'page.pageInfo.clickSource'));
  }

  function generateAdobePlayerObject() {
    const { name: playerName, placement: location, sdk: version } = playerInfo.playerMetadata;

    const playerObject: AdobePlayerEventMetadata = {
      playerName,
      location,
      version,
      ...(playerInfo.playerUserSettings && {
        userPreferences: {
          subtitleLanguage: playerInfo.playerUserSettings.subtitleLanguage,
          subtitleLanguageLabel: playerInfo.playerUserSettings.subtitleLabel,
          audioTrackSelected: playerInfo.playerUserSettings.audioLanguage,
          audioTrackLabel: playerInfo.playerUserSettings.audioLabel,
        },
      }),
    };

    return playerObject;
  }

  function addAdobeEvent(name, attributes) {
    const payload = {
      event: name,
      ...attributes,
      identification: {
        hashedEmail: userInformation.emailHash,
        janrainUUID: userInformation.userId,
      },
      ...(playerInfo.playerMetadata && { player: generateAdobePlayerObject() }),
    };

    if (process.env.BVAR_ADOBETM_SCRIPT) {
      Logger.info('An event is added to adobeDataLayer', payload);
      window.adobeDataLayer.push(payload);
    }
  }

  function dispatchAdobePageView(title: string, errorCode: string = '', errorMessage: string = '') {
    const categories = getPageCategories();
    const [
      , // The first section is /ondemand and is skipped since we hardcode it as siteSection below
      siteSubSection1 = '',
      siteSubSection2 = '',
      siteSubSection3 = '',
      siteSubSection4 = '',
    ] = categories;

    addAdobeEvent(
      'pageView',
      {
        event: 'pageView',
        page: {
          name: pageInformation.name,
          previousPageName: pageInformation.previousName,
          URL: window.location.href,
          title,
          pageID: getPageId(),
          siteSection: 'sbs on demand',
          siteSubSection1,
          siteSubSection2,
          siteSubSection3,
          siteSubSection4,
          pageLanguage: pageInformation.language,
          clickSource: adobeClickSource || {
            clickSourceTitle: '',
            clickSourceType: '',
            clickSourceTileNumber: null,
            clickSourceRowNumber: null,
          },
          error: {
            errorCode,
            errorMessage,
          },
        },
        browserDetails: generateBrowserDetails(),
        userAccount: {
          loggedIn: userInformation.isLoggedIn,
        },
      },
    );

    if (adobeClickSource) {
      adobeClickSource = undefined;
    }
  }

  function setUserAgent() {
    digitalData.environment.browser.userAgent = navigator.userAgent;
  }

  function setDeviceLanguage() {
    digitalData.environment.platform.deviceLanguage = navigator.language;
  }

  function resetProp(path) {
    set(digitalData, path, cloneDeep(get(defaultDigitalData, path)));
  }

  function getCookie(name) {
    const match = new RegExp(`${name}=([^;]+)`).exec(document.cookie);
    if (match) {
      return match[1];
    }
    return null;
  }

  function getFullConsumerAdvices(consumerAdvices) {
    return consumerAdvices.map((a) => {
      switch (a) {
        case 'a':
          return 'Adult themes and/or dangerous stunts';
        case 'd':
          return 'Drug references and/or drug use';
        case 'h':
          return 'Horror or supernatural themes';
        case 'l':
          return 'Coarse language';
        case 'n':
          return 'Nudity';
        case 's':
          return 'Sexual references and/or sex scenes';
        case 'v':
          return 'Violence';
        default:
          return '';
      }
    });
  }

  function generateVideoObject(item: Partial<OnDemand.Video | OnDemand.TvSeries>) {
    const videoObject: DataLayerVideoObject = {
      videoInfo: {
        videoName: '',
        mpxID: '',
        pilatID: '',
        length: '',
        videoURL: '',
      },
      attribute: {
        contentType: '',
        channel: [],
        programName: '',
        episodeNumber: '',
        seasonNumber: '',
        language: [],
        countryOfOrigin: '',
        classification: '',
        awardNomination: '',
        genre: [],
        subGenre: [],
        theme: [],
        tone: [],
        collection: [],
        keywords: [],
        subject: [],
      },
    };

    if (item.type === 'TVSeries') {
      videoObject.attribute.programName = item.title;
    }

    if (item.type === 'Movie' || item.type === 'OneOff' || item.type === 'Episode' || item.type === 'Clip') {
      videoObject.videoInfo.videoName = item.mediaTitle;
      videoObject.videoInfo.mpxID = item.id;
      videoObject.videoInfo.pilatID = get(item, 'pilatId', '');
      videoObject.videoInfo.length = item.duration ? toString(item.duration) : '';
      videoObject.videoInfo.videoURL = generateFullUrlFromLinkProps(item.route, digitalData.page.pageInfo.language);

      videoObject.attribute.contentType = item.useType ? `video:${item.useType.toLowerCase()}` : '';
      videoObject.attribute.channel = get(item, 'channels', []);

      if (item.type === 'Episode') {
        videoObject.attribute.programName = item.episodeData.programName;
        videoObject.attribute.episodeNumber = toString(item.episodeData.episodeNumber);
        videoObject.attribute.seasonNumber = toString(item.episodeData.seasonNumber);
      } else if (item.type === 'Movie' || item.type === 'OneOff') {
        videoObject.attribute.programName = item.mediaTitle;
      }

      videoObject.attribute.language = get(item, 'languages', []);
      videoObject.attribute.countryOfOrigin = item.countries ? item.countries.join(', ') : '';
      videoObject.attribute.classification = item.classification;
      videoObject.attribute.awardNomination = get(item, 'award', '');
      videoObject.attribute.genre = item.genres;
      videoObject.attribute.subGenre = item.subgenres ? item.subgenres.map((subGenre) => {
        return subGenre.replace(/^.*?\//, '');
      }) : [];
      videoObject.attribute.theme = get(item, 'themes', []);
      videoObject.attribute.tone = get(item, 'tones', []);
      videoObject.attribute.collection = get(item, 'collections', []);
      videoObject.attribute.subject = get(item, 'subjects', []);
      videoObject.attribute.keywords = get(item, 'keywords', []);
    }

    return videoObject;
  }

  function generateMediaPart(currentChapterData: { current: number, total: number }, actionName: string) {
    let mediaPart = '';
    const { current, total } = currentChapterData;
    if (actionName === 'videoCompleted') {
      mediaPart = `${current + 1}.${total}`;
    } else {
      mediaPart = `${current}.${total}`;
    }

    return mediaPart;
  }

  function generateAdobeMediaObjectWithAction(item: Partial<OnDemand.Video | OnDemand.TvSeries>, actionName: string, additionalAttributes) {
    const { currentChapterData } = additionalAttributes;
    const { videoPlayerEvent, videoNavigationEvent } = additionalAttributes;
    // map the action name with progress
    const actionMap = {
      videoLoaded: 'load',
      videoStarted: 'start',
      videoChapterBreak: 'chapterBreak',
      videoMilestone25: 'milestone25',
      videoMilestone50: 'milestone50',
      videoMilestone75: 'milestone75',
      videoMilestone95: 'milestone95',
      videoCompleted: 'complete',
      videoSkipBackward: 'skipBackward',
      videoSkipForward: 'skipForward',
      videoPause: 'pause',
    };

    const mediaObject = {
      ...generateAdobeMediaObject(item),
      ...(videoPlayerEvent && {
        videoPlayerEvent,
      }),
      ...(videoNavigationEvent && {
        videoNavigationEvent,
      }),
      ...(actionName && actionMap[actionName] && {
        progress: actionMap[actionName],
      }),
    };

    mediaObject.videoDetails.mediaPart = has(currentChapterData, 'current') ? generateMediaPart(currentChapterData, actionName) : '';

    return mediaObject;
  }

  function generateAdobeRecommendationObject(recommendedVideoData: Partial<OnDemand.Video | OnDemand.Episode>) {
    const recommendationVideoDetails: AdobeRecommendationDetails = {
      videoInfo: {
        videoName: recommendedVideoData.mediaTitle,
        mpxID: recommendedVideoData.id,
        pilatID: recommendedVideoData.pilatId,
        length: recommendedVideoData.duration ? toString(recommendedVideoData.duration) : '',
        videoURL: generateFullUrlFromLinkProps(recommendedVideoData.route, pageInformation.language),
      },
    };

    return recommendationVideoDetails;
  }

  function delayEvent(
    name,
    action,
    additionalInfo,
    additionalAttributes,
    queueOptions: DataLayerLSQueueOptions,
  ) {
    const event = {
      event: name,
      eventAttribute: {
        ...additionalAttributes,
        action,
      },
      additionalInfo,
    };

    const dlQueue: DataLayerLSQueue = ls.get('od.dataLayerQueue') || { events: [] };
    dlQueue.events.push({
      ...queueOptions,
      data: event,
    });
    ls.set('od.dataLayerQueue', dlQueue);
    Logger.info('DataLayer: delayed event', { event });
  }

  function processLocalStorageQueue() {
    const dlQueue: DataLayerLSQueue = ls.get('od.dataLayerQueue') || { events: [] };
    while (dlQueue.events.length > 0) {
      const queuedEvent = dlQueue.events.shift();

      const now = new Date().getTime();
      if (now < queuedEvent.expires) {
        let isValid = true;
        const expectedUrl = get(queuedEvent, 'expectedUrl');

        if (
          expectedUrl
          && expectedUrl !== window.location.pathname
        ) {
          isValid = false;
          Logger.warn(`DataLayer event not expecting this URL: ${queuedEvent.data.url} vs ${window.location.pathname}`);
        }

        if (isValid) {
          // TODO: push this to adobe event?
          // digitalData.events.push(queuedEvent.data);
          Logger.info('DataLayer: add event', { event: queuedEvent.data });
        }
      } else {
        Logger.error('DataLayer queued event has expired');
      }

      ls.set('od.dataLayerQueue', dlQueue);
    }
  }

  return {
    init() {
      Logger.info('DataLayer: init');
      digitalData = cloneDeep(defaultDigitalData);
      setUserAgent();
      setDeviceLanguage();
      window.digitalData = digitalData;
      window.adobeDataLayer = window.adobeDataLayer || [];
    },
    updatePageLanguage(lang) {
      digitalData.page.pageInfo.language = lang;
      pageInformation.language = lang;
    },
    updatePageData() {
      Logger.info('DataLayer: update page data');
      // clear existing error
      digitalData.page.attributes.error.errorCode = '';
      digitalData.page.attributes.error.errorPage = '';
      digitalData.page.attributes.error.errorMessage = '';

      setPageCategory();
      setPageInfo();
    },
    partialUpdatePageData() {
      Logger.info('DataLayer: partial update page data');
      digitalData.page.pageInfo.title = document.title;
      digitalData.page.attributes.content.title = document.title;
    },
    resetPageAttribute() {
      resetProp('page.attributes');
    },
    updatePageAttributesForVideo(video) {
      Logger.info('DataLayer: update page attributes for a video');
      resetProp('page.attributes');
      const { content, ondemand } = digitalData.page.attributes;

      content.contentLanguage = video.languages;
      content.contentType = 'video';

      content.program = video.type === 'Episode' ? video.episodeData.programName : '';
      content.programSlug = video.type === 'Episode' ? video.episodeData.seriesRoute.params.slug : '';
      content.recommendable = video.type !== 'Clip';
      content.title = document.title;
      content.theme = video.themes;

      ondemand.classification = [video.classification];
      ondemand.consumerAdvice = getFullConsumerAdvices(video.consumerAdvices);
      ondemand.countryOfOrigin = video.countries;
      ondemand.era = video.eras;
      ondemand.expirationDate = video.expiredDate;
      ondemand.genre = video.genres;
      ondemand.grouping = video.groupings;
      ondemand.organisation = video.organisations;
      ondemand.place = video.places.concat(video.locations);
      ondemand.programType = [video.useType];
      ondemand.pubDate = video.airDate;
      ondemand.subject = video.subjects;
      ondemand.tone = video.tones;
      ondemand.yearOfProduction = toInteger(video.publicationYear);

      ondemand.videoName = video.title;
      ondemand.mpxId = video.id;
      ondemand.channel = video.channels;
      ondemand.programName = video.title;
      ondemand.awardNomination = video.award;
      ondemand.language = video.languages;
      ondemand.subGenre = video.subgenres.map((subGenre) => {
        return subGenre.replace(/^.*?\//, '');
      });
      ondemand.theme = video.themes;
      ondemand.collection = video.collections;
      ondemand.keywords = video.keywords;

      if (video.type === 'Episode') {
        ondemand.episodeNumber = toString(video.episodeData.episodeNumber);
        ondemand.seasonNumber = toString(video.episodeData.seasonNumber);
        ondemand.episodeTitle = video.title;
        ondemand.season = toString(video.episodeData.seasonNumber);
      }
    },
    updatePageAttributesForSeries(series) {
      Logger.info('DataLayer: update page attributes for a series');
      resetProp('page.attributes');
      const { page: { attributes: { content, ondemand } } } = digitalData;

      content.contentType = 'program';
      content.contentLanguage = series.languages;
      content.program = series.title;
      content.programSlug = series.route.params.slug;
      content.recommendable = true;

      ondemand.classification = [series.classification];
      ondemand.consumerAdvice = getFullConsumerAdvices(series.consumerAdvices);
      ondemand.countryOfOrigin = series.countries;
      ondemand.genre = series.genres;
      ondemand.programName = series.title;
    },
    updateUserData(userId, emailHash) {
      Logger.info('DataLayer: update user data', { userId, emailHash });
      resetProp('user');

      userInformation.userId = userId || '';
      userInformation.emailHash = emailHash || '';
      userInformation.isLoggedIn = !!userId;

      const { user: { userInfo, userSegment } } = digitalData;
      userInfo.userID = userInformation.userId;
      userInfo.loggedIn = !!userId;

      let syncUserIdVal = getCookie('s_vi');
      if (syncUserIdVal) {
        userInfo.syncUserID.push({
          idType: 'MCIDvi',
          idValue: syncUserIdVal,
        });
      }

      syncUserIdVal = getCookie('s_fid');
      if (syncUserIdVal) {
        userInfo.syncUserID.push({
          idType: 'MCIDfid',
          idValue: syncUserIdVal,
        });
      }

      syncUserIdVal = ls.get('_cX_G');
      if (syncUserIdVal) {
        userInfo.syncUserID.push({
          idType: 'CXID',
          idValue: syncUserIdVal,
        });
      }

      const segmentInfo = ls.get<string>('_cX_segmentInfo');
      let segments = [];
      if (segmentInfo) {
        segments = segmentInfo.split('.');

        // remove extraneous string from the first segment
        let firstSegment = segments.shift();
        firstSegment = firstSegment.split('_').pop();
        segments.unshift(firstSegment);
      }

      userSegment.cxenseSegmentIDs = segments;

      if (userDataUpdatePromiseResolve) {
        userDataUpdatePromiseResolve();
      }
    },
    updatePlayerInfo(playerData: PlayerMetadata, playerUserSettings?: PlayerUserSettings) {
      playerInfo.playerMetadata = playerData;
      playerInfo.playerUserSettings = playerUserSettings;
    },
    setClickSource(clickSourceTitle, clickSourceType, clickSourceRowNumber, clickSourceTileNumber) {
      clickSource.clickSourceTitle = clickSourceTitle;
      clickSource.clickSourceType = clickSourceType;
      clickSource.clickSourcePlacement = digitalData.page.pageInfo.pageName;

      adobeClickSource = {
        clickSourceRowNumber: clickSourceRowNumber.toString(),
        ...(clickSourceTileNumber && { clickSourceTileNumber: clickSourceTileNumber.toString() }),
        clickSourceTitle,
        clickSourceType: clickSourceType.split(':')[0],
      };

      Logger.info('DataLayer: set click source', { clickSource, adobeClickSource });
    },
    setPageError(errorCode: string, errorMessage: string) {
      digitalData.page.attributes.error.errorPage = `${errorCode} error`;
      digitalData.page.attributes.error.errorMessage = errorMessage;
      digitalData.page.attributes.error.errorCode = errorCode;
    },
    generateVideoObject,
    events: {
      videoTrackingEvent(
        actionName,
        videoData,
        additionalAttributes,
      ) {
        const adobeUnsupportedActionNames = [
          'videoAdStart',
          'videoAdComplete',
          'videoAdHoliday',
        ];
        let personalisation = get(additionalAttributes, 'personalisation');
        if (isEmpty(personalisation)) {
          personalisation = { recommendationId: '', recommendationVariantName: '' };
        }

        if (!adobeUnsupportedActionNames.includes(actionName)) {
          addAdobeEvent(
            actionName,
            {
              media: generateAdobeMediaObjectWithAction(videoData, actionName, additionalAttributes), // generate media from videoData
              personalisation,
            },
          );
        }
      },
      videoPlaybackTrackingEvent(
        actionName,
        eventDetails,
      ) {
        addAdobeEvent(
          actionName,
          eventDetails,
        );
      },
      pageLoad(title, errorCode, errorMessage) {
        pageInformation.previousName = pageInformation.name;
        pageInformation.name = getPageNameFromCategories();
        const pageBaseTitle = 'SBS On Demand';

        playerInfo.playerUserSettings = undefined;
        playerInfo.playerMetadata = undefined;

        const sendEvent = () => {
          processLocalStorageQueue();
          dispatchAdobePageView(title ? `${title} | ${pageBaseTitle}` : pageBaseTitle, errorCode, errorMessage);
        };

        userDataUpdatePromise.then(() => {
          sendEvent();
        });
      },
      addToFavourites(item, location = '', personalisation = { recommendationId: '', recommendationVariantName: '' }) {
        addAdobeEvent(
          'favouritesAdded',
          {
            favouritesDetails: {
              favourites: 'added',
              location,
            },
            media: generateAdobeMediaObject(item),
            personalisation,
          },
        );
      },
      removeFromFavourites(item, location = '', personalisation = { recommendationId: '', recommendationVariantName: '' }) {
        addAdobeEvent(
          'favouritesRemoved',
          {
            favouritesDetails: {
              favourites: 'removed',
              location,
            },
            media: generateAdobeMediaObject(item),
            personalisation,
          },
        );
      },
      addToFavouritesV2(item, location = '', personalisation = { recommendationId: '', recommendationVariantName: '' }) {
        addAdobeEvent(
          'favouritesAdded',
          {
            favouritesDetails: {
              favourites: 'added',
              location,
            },
            media: generateAdobeMediaObjectV2(item),
            personalisation,
          },
        );
      },
      removeFromFavouritesV2(item, location = '', personalisation = { recommendationId: '', recommendationVariantName: '' }) {
        addAdobeEvent(
          'favouritesRemoved',
          {
            favouritesDetails: {
              favourites: 'removed',
              location,
            },
            media: generateAdobeMediaObjectV2(item),
            personalisation,
          },
        );
      },
      queueLanguageChange(language, reloadUrl) {
        delayEvent(
          'userInteraction',
          'click',
          {
            page: digitalData.page,
            user: digitalData.user,
            environment: digitalData.environment,
          },
          {
            interactionName: `ondemand:menu:ml:languageselect:${getLanguageNameByCode(language)}`,
          },
          {
            expires: new Date().getTime() + 60000,
            expectedUrl: reloadUrl,
          },
        );
      },
      playNextEpisode(
        action,
        videoData,
        playerData,
        additionalAttributes,
      ) {
        addAdobeEvent(
          `playNextEpisode_${action}`,
          {
            media: generateAdobeMediaObjectWithAction(videoData, action, additionalAttributes),
          },
        );
      },
      videoNavigation(
        actionName,
        videoData,
        playerData,
        additionalAttributes,
      ) {
        addAdobeEvent(
          actionName,
          {
            media: generateAdobeMediaObjectWithAction(videoData, actionName, additionalAttributes), // generate media from videoData
          },
        );
      },
      recEndCard(
        action,
        videoData,
        recommendedVideoData,
        playerData,
        additionalAttributes,
      ) {
        addAdobeEvent(
          `${action}`,
          {
            media: generateAdobeMediaObjectWithAction(videoData, action, additionalAttributes),
            recommendationVideoDetails: generateAdobeRecommendationObject(recommendedVideoData),
          },
        );
      },
      newsletterPromo(
        interactionName,
      ) {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: `ondemand:banner:subscribe-banner:${interactionName}`,
            },
          },
        );
      },
      liveTvPageClickAction(
        interactionName,
      ) {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: `ondemand:live-tv-page:click:${interactionName}`,
            },
          },
        );
      },
      episodePickerViewAll() {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: 'ondemand:video-player:view-all',
            },
          },
        );
      },
      episodePickerSelectSeason(
        seasonName,
      ) {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: `ondemand:video-player:select-season:${seasonName}`,
            },
          },
        );
      },
      episodePickerSelectEpisode(
        episodeName,
        videoId,
      ) {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: `ondemand:video-player:select-episode:${episodeName}`,
            },
          },
        );
      },
      pipActivated() {
        addAdobeEvent(
          'pictureInPicture_turnOn',
          {
            media: {
              videoPlayerEvent: {
                pictureInPicture: 'turnOn',
              },
            },
          },
        );
      },
      adOnPauseDismiss(
        actionName,
        additionalAttributes,
      ) {
        addAdobeEvent(
          'userInteraction',
          additionalAttributes,
        );
      },
      clickSignInButton() {
        addAdobeEvent(
          'pageView',
          {
            page: {
              name: 's:login:sign-in',
              previousPageName: digitalData.page.pageInfo.pageName,
              title: digitalData.page.pageInfo.title,
            },
            browserDetails: generateBrowserDetails(),
          },
        );
      },
      clickCreateAccountButton() {
        addAdobeEvent(
          'pageView',
          {
            page: {
              name: 's:login:create-account',
              previousPageName: digitalData.page.pageInfo.pageName,
              title: digitalData.page.pageInfo.title,
            },
            browserDetails: generateBrowserDetails(),
          },
        );
      },
      searchResult(type, term, keyword) {
        addAdobeEvent(
          'searchResult',
          {
            searchDetails: {
              searchType: `${type}Click`,
              searchTerm: term,
              typedSearchKeyword: keyword || term,
            },
          },
        );
      },
      sortDropDownDisplay() {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: 'ondemand:web:sort:dropDownDisplay',
            },
          },
        );
      },
      sortDropDownSelect(value) {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: `ondemand:web:sort:dropDown:${normalise(value)}`,
            },
          },
        );
      },
      filterModalSortSubmit(value) {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: `ondemand:web:sort:modal:${normalise(value)}`,
            },
          },
        );
      },
      filterModalDisplay() {
        addAdobeEvent(
          'filterInteraction',
          {
            filterDetails: {
              filterInteraction: 'modalDisplay',
            },
          },
        );
      },
      filterSortModalDisplay() {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: 'ondemand:web:sort:modalDisplay',
            },
          },
        );
      },
      filterModalClearAll() {
        addAdobeEvent(
          'filterInteraction',
          {
            filterDetails: {
              filterInteraction: 'clearAll',
            },
          },
        );
      },
      filterModalShowResult(filters, totalResult) {
        const filterSelections = transform(filters, (result, value, key) => {
          // eslint-disable-next-line no-param-reassign
          result[`filterSelection_${key}`] = value.map(normalise).join(',');
        }, {});

        addAdobeEvent(
          'filterInteraction',
          {
            filterDetails: {
              filterInteraction: 'showResults',
              ...filterSelections,
              filterSelection_totalResults: totalResult.toString(),
            },
          },
        );
      },
    },
  };
})();

export default DataLayer;
