import { IconButton } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Heart from '@@icons/Heart';
import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { addContentToFavourites, removeContentFromFavourites, isInFavourite, FavouritedContent } from '@@stores/FavouritesStoreAsyncActions';
import { getRecommendation, RecommendationData } from '@@stores/RecommendationStore';
import { getIsLoggedIn, getIsReady } from '@@stores/UserStore';
import DataLayer from '@@utils/DataLayer';

import type OnDemand from '../../@types/OnDemand';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as FavouriteIcon } from '../../images/icons/favourite.svg';
import blue from '../../styles/colors/blue';
import orange from '../../styles/colors/orange';
import Button from '../Inputs/Button';
import { dispatchOpenSignIn } from '../Login/LoginForm';

export const useFavouriteButtonStyles = makeStyles(() => {
  return createStyles({
    root: {
      padding: 0,
      // not in favourite keyboard focus
      'body:not(.using-mouse) &:focus svg [class*="heart"]': {
        fill: common.white,
      },
      '&.Mui-focusVisible': {
        borderRadius: '50%',
        outline: `3px solid ${blue.navy} !important`,
      },
      '& svg': {
        color: common.white,
        '@media (hover: hover)': {
          // not in favourite hover
          '&:hover [class*="heart"]': {
            fill: common.white,
          },
          '&:hover, &:focus': {
            '& [class*="close"]': {
              opacity: 1,
            },
          },
        },
        '& [class*="circle"]': {
          fill: 'rgba(0,0,0,0.25)',
        },
        // x button
        '& [class*="close"]': {
          opacity: 0.7,
        },
      },
      '&.in-favourite': {
        // in favourite keyboard focus
        'body:not(.using-mouse) &:focus svg': {
          '& [class*="circle"]': {
            color: common.white,
          },
          '& [class*="heart"]': {
            fill: orange.darkTangerine,
          },
        },
        '& svg': {
          color: orange.darkTangerine,
          '& [class*="heart"], &:hover [class*="heart"]': {
            fill: orange.darkTangerine,
          },
          // in favourite hover
          '@media (hover: hover)': {
            '&:hover [class*="circle"]': {
              color: common.white,
            },
          },
        },
      },
      '& .MuiButton-iconSizeMedium svg': {
        width: '2.4em',
        height: '2.4em',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });
});

interface OdFavouriteButtonProps {
  item: Partial<OnDemand.Video | OnDemand.TvSeries>;
  buttonType?: 'favourite' | 'close';
  showLabel?: boolean;
  tabIndex?: number;
  classes?: any;
  children?: React.ReactNode;
  onClick?(e: MouseEvent, favourited: boolean, item: Partial<OnDemand.Video | OnDemand.TvSeries>): void;
  location?: string;
  autoAdd?: boolean;
}

const OdFavouriteButton: FunctionComponent<OdFavouriteButtonProps> = (props) => {
  const {
    item,
    tabIndex = 0,
    showLabel = false,
    onClick,
    buttonType = 'favourite',
    location: buttonLocation = '',
    autoAdd = false,
  } = props;
  const classes = useFavouriteButtonStyles(props);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  const loggedIn = useAppSelector(getIsLoggedIn);
  const loginStatusIsReady = useAppSelector(getIsReady);
  const recommendation = useAppSelector<RecommendationData>(getRecommendation);

  const inFavourite = useAppSelector((state) => {
    return isInFavourite(state, item);
  });

  let label;
  if (inFavourite) {
    label = t('button.removeFromFavourites');
  } else {
    label = t('button.addToFavourites');
  }

  const toggleFavourite = useCallback((forcedAdd: boolean = false) => {
    let action;
    let hasFavourited = false;

    let personalisation;
    if (recommendation) {
      const { recommendationId, recommendationVariantName, target } = recommendation;
      if (('catalogueId' in item && target.id === item.catalogueId) || target.id === item.id) {
        personalisation = { recommendationId, recommendationVariantName };
      }
    }

    if (!inFavourite) {
      if (buttonLocation) {
        DataLayer.events.addToFavourites(item, buttonLocation, personalisation);
      }
      action = addContentToFavourites(item);
      hasFavourited = true;
    } else if (!forcedAdd) {
      let itemToRemove: FavouritedContent = {
        id: item.id,
        type: item.type,
      };

      // Removing an episode from the FavouritePage will actually remove the episode.
      // From other locations, it will remove the related tv series.
      if (buttonType !== 'close') {
        if (item.entityType === 'Episode') {
          itemToRemove = {
            type: 'TVSeries',
            id: get(item, 'seriesId'),
          };
        } else if (get(item, 'type') === 'Episode') {
          itemToRemove = {
            type: 'TVSeries',
            id: get(item, 'episodeData.seriesId'),
          };
        }
      }

      if (buttonLocation) {
        DataLayer.events.removeFromFavourites(item, buttonLocation, personalisation);
      }

      action = removeContentFromFavourites(itemToRemove);
    }

    if (action) {
      if (!loggedIn) {
        dispatchOpenSignIn(action);
      } else {
        dispatch(action);
      }
    }

    return hasFavourited;
  }, [buttonLocation, buttonType, dispatch, inFavourite, item, loggedIn, recommendation]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const hasFavourited = toggleFavourite();

    if (onClick) {
      onClick(e, hasFavourited, item);
    }
  }, [item, onClick, toggleFavourite]);

  useEffect(() => {
    if (loginStatusIsReady && autoAdd) {
      toggleFavourite(true);
    }
  }, [autoAdd, loginStatusIsReady, toggleFavourite]);

  const buttonProps = {
    title: label,
    'aria-label': label,
    onClick: handleClick,
    tabIndex,
  };

  if (showLabel) {
    return (
      <Button
        alternateColor={inFavourite}
        buttonType="text"
        size="large"
        startIcon={<Heart/>}
        {...buttonProps}
      >
        {label}
      </Button>
    );
  }

  const Icon = buttonType === 'favourite' ? FavouriteIcon : CloseIcon;

  return (
    <IconButton
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...buttonProps}
      classes={{
        root: clsx(classes.root, inFavourite ? 'in-favourite' : ''),
      }}
    >
      <Icon/>
    </IconButton>
  );
};

export default OdFavouriteButton;
