import SbsLogin from '@sbs/sbs-login';
import jwtDecode from 'jwt-decode';

import { getSbsLoginInstance } from '@@utils/SbsLoginUtils';
import { CHROMECAST_RCV_APP_V3_ID } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';

export enum CastPlayerEventType {
  CAST_SESSION_STARTING = 'castSessionStarting',
}

const CAST_NAMESPACE = 'urn:x-cast:com.sbsod.player.caf';
const ENTITY_ID_PREFIX = 'mpx:';

class CastPlayer {
  /**
   * Singleton instance of the `CastPlayer` class.
   */
  private static instance: CastPlayer;

  /**
   * SBS Login instance associated with the CastPlayer.
   */
  private sbsLogin: SbsLogin | null = null;

  /**
   * Remote player instance for managing playback on the Cast device.
   */
  private remotePlayer: cast.framework.RemotePlayer | null = null;

  /**
   * Remote player controller for controlling the playback on the Cast device.
   */
  private remotePlayerController: cast.framework.RemotePlayerController | null = null;

  /**
   * Cast context instance for managing the Cast SDK's context.
   */
  public castContext: cast.framework.CastContext;

  /**
   * ID of the set interval callback which is used to update the cast player session token
   */
  private refreshTokenTimeoutActive: boolean = false;

  /**
   * Private constructor for initializing the class instance.
   * @constructor
   * @private
   */
  private constructor() {
    getSbsLoginInstance().then((result) => {
      this.sbsLogin = result;
    });

    this.castContext = cast.framework.CastContext.getInstance();
    this.castContext.setOptions({
      receiverApplicationId: CHROMECAST_RCV_APP_V3_ID,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });
  }

  /**
   * Gets the singleton instance of the `CastPlayer` class.
   * @returns The singleton instance of the `CastPlayer` class.
   * @remarks
   * - If an instance already exists, it is returned; otherwise, a new instance is created.
   */
  public static get current(): CastPlayer {
    if (!CastPlayer.instance) {
      CastPlayer.instance = new CastPlayer();
    }
    return CastPlayer.instance;
  }

  /**
   * Gets the current CastSession from the CastContext instance.
   */
  public get currentSession(): cast.framework.CastSession | null {
    return this.castContext.getCurrentSession();
  }

  public static get isChromeCastSdkAvailable(): boolean {
    return !!window?.chrome && !!window?.chrome.cast && !!window?.cast;
  }

  public static hasAvailableDevices(_castState: cast.framework.CastState | undefined = undefined): boolean {
    if (this.isChromeCastSdkAvailable) {
      const castState = _castState || cast.framework.CastContext.getInstance().getCastState();
      return castState !== cast.framework.CastState.NO_DEVICES_AVAILABLE;
    }

    return false;
  }

  /**
   * Add a callback to a generic remote player controller event (RemotePlayerEventType)
   */
  public addRemotePlayerEventListener(eventType: cast.framework.RemotePlayerEventType, callback: (event: cast.framework.RemotePlayerChangedEvent<any>) => void): void {
    if (!this.remotePlayerController) {
      this.remotePlayer = new cast.framework.RemotePlayer();
      this.remotePlayerController = new cast.framework.RemotePlayerController(
        this.remotePlayer,
      );
    }

    this.remotePlayerController.addEventListener(eventType, callback);
  }

  public addCastStateEventListener(callback: (event: cast.framework.CastStateEventData) => void): void {
    this.castContext.addEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, callback);
  }

  /**
   * Remove a callback to a generic remote player controller event (RemotePlayerEventType)
   */
  public removeRemotePlayerEventListener(eventType: cast.framework.RemotePlayerEventType, callback: (event: cast.framework.RemotePlayerChangedEvent<any>) => void): void {
    if (!this.remotePlayerController) {
      this.remotePlayer = new cast.framework.RemotePlayer();
      this.remotePlayerController = new cast.framework.RemotePlayerController(
        this.remotePlayer,
      );
    }

    this.remotePlayerController.removeEventListener(eventType, callback);
  }

  /**
   * Add a callback directly to the cast framework SESSION_STATE_CHANGED event
   */
  public addSessionStateEventListener(callback: (event: cast.framework.SessionStateEventData) => void): void {
    this.castContext.addEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, callback);
  }

  /**
   * Remove a callback directly to the cast framework SESSION_STATE_CHANGED event
   */
  public removeSessionStateEventListener(callback: (event: cast.framework.SessionStateEventData) => void): void {
    this.castContext.removeEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, callback);
  }

  public removeCastStateEventListener(callback: (event: cast.framework.CastStateEventData) => void): void {
    this.castContext.removeEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, callback);
  }

  /**
   * Unloads the Cast session and stops the remote player controller
   */
  public unload(): void {
    if (this.remotePlayerController) {
      this.remotePlayerController.stop();
    }

    this.currentSession?.endSession(true);
  }

  /**
   * Loads a video onto the current Cast session using the provided entity ID.
   * Set up timeout to refresh auth token on receiver application
   */
  public async loadVideo(entityId: string, resumePosition: number, sessionId: string, isLive: boolean): Promise<void> {
    try {
      if (!this.currentSession) {
        throw new Error('No Cast Session');
      }

      if (!this.sbsLogin) {
        throw new Error('SBS Login has not be initialised');
      }

      const loadRequest: any = {};

      loadRequest.currentTime = resumePosition ?? 0;
      loadRequest.credentials = await this.sbsLogin.getIdToken();
      loadRequest.media = {
        entity: ENTITY_ID_PREFIX + entityId,
        streamType: isLive ? 'LIVE' : 'BUFFERED',
        customData: {
          sbsSession: sessionId,
        },
      };

      await this.currentSession.loadMedia(loadRequest).catch((error: chrome.cast.ErrorCode) => {
        throw new Error(`Chrome cast error code: ${error}`);
      });

      Logger.debug('Sent load media request to cast session', { loadRequest });
    } catch (error) {
      Logger.error('Failed to load media', error);
    }
  }

  /**
   * Cast Player API controls
   */
  public play(): void {
    if (
      this.remotePlayer
      && this.remotePlayerController
      && this.remotePlayer.isPaused
    ) {
      this.remotePlayerController.playOrPause();
    }
  }

  public pause(): void {
    if (
      this.remotePlayer
      && this.remotePlayerController
      && this.remotePlayer.canPause
      && !this.remotePlayer.isPaused
    ) {
      this.remotePlayerController.playOrPause();
    }
  }

  public seek(time: number) {
    if (this.remotePlayer && this.remotePlayerController) {
      this.remotePlayer.currentTime = time;
      this.remotePlayerController.seek();
    }
  }

  public get duration(): number {
    if (this.remotePlayer) {
      return this.remotePlayer.duration;
    }
    return 0;
  }

  public getLiveSeekableRange() {
    if (this.remotePlayer) {
      if (this.currentSession) {
        const media = this.currentSession.getMediaSession();
        // @ts-ignore, not defined in type file
        return media.getEstimatedLiveSeekableRange();
      }

      return this.remotePlayer.liveSeekableRange;
    }

    return null;
  }

  public seekToLive() {
    if (this.remotePlayerController) {
      const seekableRange = this.getLiveSeekableRange();

      if (seekableRange) {
        this.seek(seekableRange.end);
      }
    }
  }

  /**
   * Requests a new track on the media session. If successful, invokes the successCallback;
   * otherwise, invokes the errorCallback.
   */
  public requestTrack(trackId: number, successCallback: () => void, errorCallback: (e: chrome.cast.Error) => void): void {
    if (!this.currentSession) {
      Logger.error('Failed to request track: no session');
      return;
    }

    const mediaSession = this.currentSession.getMediaSession();

    if (mediaSession === null) {
      Logger.error('Failed to request track: no media session');
      return;
    }

    const editTracksInfoRequest = new chrome.cast.media.EditTracksInfoRequest([trackId]);

    const media = new chrome.cast.media.Media(mediaSession.sessionId, mediaSession.mediaSessionId);
    media.editTracksInfo(editTracksInfoRequest, successCallback, errorCallback);
  }

  /**
   * Retrieves the active track IDs from the current media session if available
   */
  public get activeTrackIds(): number[] | undefined {
    if (!this.currentSession) {
      Logger.error('Failed to get active tracks: no session');
      return undefined;
    }

    const mediaSession = this.currentSession.getMediaSession();

    if (mediaSession === null) {
      Logger.error('Failed to get active tracks: no media session');
      return undefined;
    }

    return mediaSession.activeTrackIds;
  }
}

export default CastPlayer;
