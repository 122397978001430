import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pull, uniq } from 'lodash';

interface SnackPack {
  type: string;
  key: string;
}

export interface FavouritesStoreState {
  videos: string[];
  series: string[];
  snackPack: SnackPack[];
}

const initialState: FavouritesStoreState = {
  videos: [],
  series: [],
  snackPack: [],
};

const favouritesStore = createSlice({
  name: 'FavouritesStore',
  initialState,
  reducers: {
    addVideo(state, action: PayloadAction<string>) {
      state.videos.push(action.payload);
    },
    removeVideo(state, action: PayloadAction<string>) {
      state.videos = pull(state.videos, action.payload);
    },
    addSeries(state, action: PayloadAction<string>) {
      state.series.push(action.payload);
    },
    removeSeries(state, action: PayloadAction<string>) {
      state.series = pull(state.series, action.payload);
    },
    add(state, action: PayloadAction<{ videos: string[]; series: string[] }>) {
      state.videos = uniq([...state.videos, ...action.payload.videos]);
      state.series = uniq([...state.series, ...action.payload.series]);
    },
    addSnackBarToPack(state, action: PayloadAction<string>) {
      state.snackPack = [...state.snackPack, { type: action.payload, key: new Date().getTime().toString() }];
    },
    setSnackPack(state, action: PayloadAction<SnackPack[]>) {
      state.snackPack = action.payload;
    },
    clear(state) {
      state.videos = [];
      state.series = [];
      state.snackPack = [];
    },
  },
});

export default favouritesStore;
export const { setSnackPack, addSnackBarToPack } = favouritesStore.actions;
