import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Play from '@@icons/Play';
import DistributorLogo from '@@src/components/Advert/DistributorLogo';
import { hasFeature } from '@@utils/config';

import OnDemand from '../../@types/OnDemand';
import useQuery from '../../hooks/useQuery';
import { ReactComponent as AdBadge } from '../../images/badges/ad.svg';
import { ReactComponent as CcBadge } from '../../images/badges/cc.svg';
import { ReactComponent as HdBadge } from '../../images/badges/hd.svg';
import { generatePathFromLinkProps } from '../../routes';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';
import Sponsorship from '../Advert/Sponsorship';
import OdFavouriteButton from '../Buttons/OdFavouriteButton';
import Button from '../Inputs/Button';
import ClassificationBadge from '../Utils/ClassificationBadge';
import OdContainer from '../Utils/OdContainer';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      borderRadius: 0,
      width: '100%',
      position: 'relative',
    },
    heroContent: {
      opacity: 1,
      textAlign: 'left',
      backgroundColor: 'transparent',
      width: '56%',
      position: 'relative',
      color: 'white',
      [theme.breakpoints.down('lg')]: {
        width: '75%',
        maxWidth: 1100,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingTop: 0,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingTop: 0,
      },
    },
    title: {
      fontFamily: fontFamily.ubuntu,
      fontSize: '4.5rem',
      fontWeight: 'bold',
      lineHeight: 1,
      position: 'relative',
      padding: 0,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: '3.25rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.125rem',
      },
    },
    genreContainer: {
      display: 'inline-flex',
      gap: 12,
      fontFamily: fontFamily.ubuntu,
      fontSize: '1.375rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      marginBottom: 4,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.125rem',
      },
      '& $badges': {
        paddingTop: 3,
      },
      '.live &': {
        marginBottom: 18,
      },
    },
    topMetadata: {
      fontFamily: fontFamily.secondary,
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: '1.125rem',
      fontWeight: 500,
      '& > span': {
        marginRight: 16,
      },
      lineHeight: 1,
      marginBottom: 13,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
      '& span': {
        marginBottom: 12,
      },
    },
    bottomMetadata: {
      fontSize: '1.125rem',
      marginBottom: 26,
      '& $metadataLabel': {
        marginRight: 8,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    bottomMetadataRow: {
      marginBottom: 8,
    },
    bottomMetadataColumn: {
      display: 'inline-block',
      marginRight: 12,
    },
    metadataLabel: {
      display: 'inline',
      color: grey.chateau,
      textTransform: 'capitalize',
    },
    metadataValue: {
      display: 'inline',
    },
    ctaBar: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    ctaButton: {
      marginRight: theme.spacing(2),
      '& span': {
        fontFamily: fontFamily.secondary,
        fontSize: '1.125rem',
        fontWeight: 'bold',
      },
      [theme.breakpoints.only('sm')]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down('xs')]: {
        left: -12,
        marginBottom: 16,
      },
    },
    ctaLabel: {
      cursor: 'pointer',
    },
    badges: {
      '& > *:not(:last-child)': {
        marginRight: 8,
      },
      '& svg': {
        height: '1em',
      },
    },
    sponsor: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      marginBottom: 15,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    sponsorImage: {
      height: 55,
      marginLeft: 8,
      verticalAlign: 'middle',
      [theme.breakpoints.down('md')]: {
        height: 45,
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        height: 30,
        marginBottom: 0,
      },
    },
    availabilityBadge: {
      backgroundColor: orange.darkTangerine,
      color: grey.black,
      fontSize: '1rem',
      lineHeight: 1,
      textTransform: 'uppercase',
      display: 'inline-block',
      fontFamily: fontFamily.secondary,
      padding: '4px 12px',
      borderRadius: 3,
      fontWeight: 700,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 12,
      },
    },
    distributor: {
      marginLeft: 2,
    },
  });
});

interface TopMetadataListItem {
  id: string;
  value: any;
}

export interface ContentSummaryCtaButton {
  key: string;
  component: JSX.Element;
}

export interface ContentSummaryProps {
  item: OnDemand.Video | OnDemand.TvSeries;
  title?: string;
  bottomMetadata?: Record<string, string[]>[];
  topMetadata?: {
    list: TopMetadataListItem[];
    badges: {
      classification: string;
      hasClosedCaption: boolean;
      isHighDefinition: boolean;
      hasAudioDescription: boolean;
    };
  };
  showAddToFavourite?: boolean;
  children?: any;
  ctaButtons?: ContentSummaryCtaButton[];
  sponsorship?: OnDemand.Sponsorship;
}

const ContentSummary: FunctionComponent<ContentSummaryProps> = (props) => {
  const {
    title = null,
    item,
    children,
    bottomMetadata, topMetadata,
    showAddToFavourite = true,
    ctaButtons = [],
    sponsorship = null,
  } = props;
  const classes = useStyles({});
  const hasCc = get(topMetadata, 'badges.hasClosedCaption', false);
  const isHd = get(topMetadata, 'badges.isHighDefinition', false);
  const hasAd = get(topMetadata, 'badges.hasAudioDescription', false);
  const { t, i18n: { language } } = useTranslation(['page', 'common']);
  const { action, addtofav } = useQuery(true);
  const autoAddToFavourite = action === 'favourite' || addtofav === '1';

  // make a copy
  const _ctaButtons: ContentSummaryCtaButton[] = ctaButtons.concat();

  if (!item.expired) {
    if (item.trailerId) {
      const to = generatePathFromLinkProps({
        name: 'watch',
        params: {
          id: item.trailerId,
        },
      });

      _ctaButtons.push({
        key: to,
        component: (
          <Button
            buttonType="text"
            size="large"
            startIcon={<Play/>}
            to={to}
          >
            {t('detailsPage.watchTrailer')}
          </Button>
        ),
      });
    }
    if (showAddToFavourite) {
      _ctaButtons.push({
        key: 'addToFavourite',
        component: (
          <OdFavouriteButton item={item} tabIndex={0} showLabel location="contentPage" autoAdd={autoAddToFavourite}/>
        ),
      });
    }
  }

  let availabilityBadge = null;

  if (!item.isLiveStream) {
    if (item.expired) {
      availabilityBadge = t('common:availabilityBadge.notAvailable');
    } else if ('available' in item && !item.available) {
      availabilityBadge = t('common:availabilityBadge.comingSoon');
    }
  }

  const classification = get(topMetadata, 'badges.classification');

  const badgesList = [];
  if (classification) {
    badgesList.push(<span key="classification"><ClassificationBadge classification={classification}/></span>);
  }
  if (hasFeature('adBadge') && hasAd) {
    badgesList.push(<span key="adAvailable"><AdBadge role="img" aria-label={t('common:metadata.ADAvailable')}/></span>);
  }
  if (hasCc) {
    badgesList.push(<span key="ccAvailable"><CcBadge role="img" aria-label={t('common:metadata.CCAvailable')}/></span>);
  }
  if (isHd) {
    badgesList.push(<span key="hdAvailable"><HdBadge role="img" aria-label={t('common:metadata.HDAvailable')}/></span>);
  }

  let badges;
  if (badgesList.length > 0) {
    badges = (
      <span key="badges" className={classes.badges}>
        {badgesList}
      </span>
    );
  }

  const genreContent = [];
  if (item.genres.length > 0) {
    genreContent.push(<span key="genres">{item.genres.join(', ')}</span>);
  }
  if (item.isLiveStream && badges) {
    genreContent.push(badges);
  }

  return (
    <div className={clsx(classes.root, { live: item.isLiveStream })}>
      <OdContainer>
        <div className={classes.heroContent}>
          {sponsorship && (
            <Sponsorship
              type="inline"
              sponsorship={sponsorship}
            />
          )}
          {
            availabilityBadge && (
              <div className={classes.availabilityBadge}>{availabilityBadge}</div>
            )
          }
          <Typography variant="h1" component="h1" className={classes.title}>
            {title || item.title}
          </Typography>

          {
            genreContent.length > 0 && (
              <div className={classes.genreContainer}>
                {genreContent}
              </div>
            )
          }

          {!item.isLiveStream && (
            <div className={classes.topMetadata}>
              {topMetadata?.list
                ?.filter((metadataEntry) => {
                  return metadataEntry.value !== null;
                })
                .map((metadataEntry) => {
                  return <span key={metadataEntry.id}>{metadataEntry.value}</span>;
                })}
              {badges}
              {item?.distributors?.map((distributor) => {
                return (
                  <DistributorLogo
                    key={distributor.id}
                    id={distributor.id}
                    name={distributor.name}
                    classes={{ root: classes.distributor }}
                  />
                );
              })}
            </div>
          )}

          {children}

          {
            bottomMetadata && (
              <div className={classes.bottomMetadata}>
                {
                  bottomMetadata.map((metadataEntry) => {
                    return (
                      <div key={Object.keys(metadataEntry).join('')} className={classes.bottomMetadataRow}>
                        {
                          Object.keys(metadataEntry).map((field) => {
                            const metadataValue = metadataEntry[field] ? metadataEntry[field].join(', ') : '';
                            const metadataLabel = t(`common:metadata.${field}`, { count: metadataEntry[field].length });

                            if (metadataValue !== '') {
                              return (
                                <div key={field} className={classes.bottomMetadataColumn}>
                                  <div className={classes.metadataLabel}>
                                    {metadataLabel}
                                    :
                                  </div>
                                  <div className={classes.metadataValue}>
                                    {metadataValue}
                                  </div>
                                </div>
                              );
                            }

                            return null;
                          })
                        }
                      </div>
                    );
                  })
                }
              </div>
            )
          }

          <div className={classes.ctaBar}>
            {
              _ctaButtons.map((ctaButton) => {
                // eslint-disable-next-line react/no-array-index-key
                return (
                  <Box ml={-1} key={ctaButton.key} className={classes.ctaButton}>
                    {ctaButton.component}
                  </Box>
                );
              })
            }
          </div>
        </div>
      </OdContainer>
    </div>
  );
};

export default ContentSummary;
