import { createSelector } from '@reduxjs/toolkit';
import { get, indexOf } from 'lodash';

import OnDemand from '@@src/@types/OnDemand';
import OnDemand2 from '@@src/@types/OnDemand2';
import favouritesStore from '@@stores/FavouritesStore';
import Logger from '@@utils/logger/Logger';

import FavouriteService from '../services/FavouritesService';
import type { RootState } from '../store';

export type FavouritedContent = {
  id: string;
  seriesId?: string;
  mpxMediaId?: string;
  entityType?: OnDemand2.EntityTypes;
  type?: 'TVSeries' | 'Episode' | 'OneOff' | 'Movie' | 'Clip';
};

function getContentIdAndType(content: FavouritedContent | Partial<OnDemand.Video | OnDemand.TvSeries>) {
  let id;
  let contentType;

  if (content.entityType === 'Episode') {
    id = get(content, 'seriesId');
    contentType = 'series';
  } else if (get(content, 'type') === 'Episode') {
    id = get(content, 'episodeData.seriesId');
    contentType = 'series';
  } else if (content.entityType === 'Series' || get(content, 'type') === 'TVSeries') {
    id = content.id;
    contentType = 'series';
  } else if (content.entityType === 'Movie' || content.entityType === 'Program') {
    id = get(content, 'mpxMediaId');
    contentType = 'video';
  } else if (get(content, 'type') === 'Movie' || get(content, 'type') === 'OneOff' || get(content, 'type') === 'Clip') {
    id = content.id;
    contentType = 'video';
  }

  return {
    id,
    contentType,
  };
}

export function addContentToFavourites(content: FavouritedContent | Partial<OnDemand.Video | OnDemand.TvSeries>) {
  const favouriteActionMap = {
    series: {
      action: favouritesStore.actions.addSeries,
      errorAction: favouritesStore.actions.removeSeries,
      service: FavouriteService.addSeries,
    },
    video: {
      action: favouritesStore.actions.addVideo,
      errorAction: favouritesStore.actions.removeVideo,
      service: FavouriteService.addVideo,
    },
  };

  const { id: idToAdd, contentType } = getContentIdAndType(content);

  const { action } = favouriteActionMap[contentType];
  const { errorAction } = favouriteActionMap[contentType];
  const { service } = favouriteActionMap[contentType];

  return (dispatch) => {
    dispatch(action(idToAdd));
    return service(idToAdd)
      .then(() => {
        dispatch(favouritesStore.actions.addSnackBarToPack('added'));
      })
      .catch(() => {
        // there is an error, let's re-add to the store
        dispatch(errorAction(idToAdd));
      });
  };
}

export function removeContentFromFavourites(content: FavouritedContent | Partial<OnDemand.Video | OnDemand.TvSeries>) {
  const favouriteActionMap = {
    series: {
      action: favouritesStore.actions.removeSeries,
      errorAction: favouritesStore.actions.addSeries,
      service: FavouriteService.removeSeries,
    },
    video: {
      action: favouritesStore.actions.removeVideo,
      errorAction: favouritesStore.actions.addVideo,
      service: FavouriteService.removeVideo,
    },
  };
  const { id: idToRemove, contentType } = getContentIdAndType(content);

  const { action } = favouriteActionMap[contentType];
  const { errorAction } = favouriteActionMap[contentType];
  const { service } = favouriteActionMap[contentType];

  return (dispatch) => {
    dispatch(action(idToRemove));
    return service(idToRemove)
      .then(() => {
        dispatch(favouritesStore.actions.addSnackBarToPack('removed'));
      })
      .catch(() => {
        // there is an error, let's re-add to the store
        dispatch(errorAction(idToRemove));
      });
  };
}

export function fetchFavourites() {
  return (dispatch) => {
    return FavouriteService.list()
      .then((result) => {
        dispatch(favouritesStore.actions.add(result));
      }).catch((e) => {
        Logger.error('There is an error when fetching favourites', {
          error: {
            message: e.message,
          },
        });
      });
  };
}

// selectors
export const allFavourites = createSelector(
  (rootState: RootState) => {
    return rootState.favourites;
  },
  (favourites) => {
    return favourites;
  },
);

export const isInFavourite = createSelector(
  (rootState: RootState) => {
    return rootState.favourites;
  },
  (_favourites, content) => {
    return content;
  },
  (favourites, content: Partial<OnDemand.Video | OnDemand.TvSeries> | OnDemand2.CollectionItem) => {
    if (content.entityType === 'Series' || get(content, 'type') === 'TVSeries') {
      return indexOf(get(favourites, 'series', []), content.id) !== -1;
    }

    if (content.entityType === 'Episode') {
      return indexOf(get(favourites, 'series', []), get(content, 'seriesId')) !== -1;
    }

    if (get(content, 'type') === 'Episode') {
      return indexOf(get(favourites, 'series', []), get(content, 'episodeData.seriesId')) !== -1;
    }

    if (content.entityType === 'Movie' || content.entityType === 'Program') {
      return indexOf(get(favourites, 'videos', []), get(content, 'mpxMediaId')) !== -1;
    }

    if (get(content, 'type') === 'Movie' || get(content, 'type') === 'OneOff' || get(content, 'type') === 'Clip') {
      return indexOf(get(favourites, 'videos', []), content.id) !== -1;
    }

    return null;
  },
);
