import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { throttle } from 'lodash';
import { Duration } from 'luxon';
import React, { useEffect, useCallback, useRef, useState } from 'react';

import orange from '@@styles/colors/orange';

const useStyles = makeStyles(() => {
  return createStyles({
    seekBarContainer: {
      '-webkit-font-smoothing': 'antialiased',
      '--controlbutton-initial-opacity': '0.65',
      '-webkit-tap-highlight-color': 'transparent',
      color: '#fff',
      lineHeight: '1em',
      outline: 0,
      pointerEvents: 'auto',
      cursor: 'pointer',
      height: '1em',
      position: 'relative',
      width: '100%',
      borderRadius: 4,
      margin: '0 1.25em',
      display: 'inline-block',
      '&.ready > *': {
        transition: '0.1s linear, 0.3s linear',
        transitionProperty: 'transform, -webkit-transform',
      },
    },
    backdrop: {
      lineHeight: '1em',
      cursor: 'pointer',
      pointerEvents: 'auto',
      bottom: 0,
      boxSizing: 'border-box',
      left: 0,
      position: 'absolute',
      right: 'auto',
      top: 0,
      transformOrigin: '0 0',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      margin: 'auto 0',
      width: '100%',
      height: '8px',
      transform: 'scaleX(0.99999)',
    },
    bufferLevel: {
      lineHeight: '1em',
      bottom: 0,
      boxSizing: 'border-box',
      left: 0,
      position: 'absolute',
      right: 'auto',
      top: 0,
      transformOrigin: '0 0',
      width: '100%',
      margin: 'auto 0',
      height: '8px',
      backgroundColor: 'rgba(255,160,255,0.4)',
      transform: 'scaleX(0)',
    },
    playbackPosition: {
      '--controlbutton-initial-opacity': 0.65,
      '-webkit-tap-highlight-color': 'transparent',
      userSelect: 'none',
      lineHeight: '1em',
      visibility: 'unset',
      cursor: 'pointer',
      pointerEvents: 'auto',
      bottom: 0,
      boxSizing: 'border-box',
      left: 0,
      position: 'absolute',
      right: 'auto',
      top: 0,
      transformOrigin: '0 0',
      width: '100%',
      margin: 'auto 0',
      height: '8px',
      backgroundColor: orange.darkTangerine,
    },
    seekPosition: {
      lineHeight: '1em',
      bottom: 0,
      boxSizing: 'border-box',
      left: 0,
      position: 'absolute',
      right: 'auto',
      top: 0,
      transformOrigin: '0 0',
      width: '100%',
      margin: 'auto 0',
      height: '8px',
      backgroundColor: 'rgba(255,255,255,0.4)',
      transform: 'scaleX(0)',
    },

    markersContainer: {
      '-webkit-tap-highlight-color': 'transparent',
      bottom: 0,
      boxSizing: 'border-box',
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 'auto',
      top: 0,
      width: '100%',
    },

    marker: {
      cursor: 'pointer',
      pointerEvents: 'auto',
      bottom: 0,
      boxSizing: 'border-box',
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 'auto',
      top: 0,
      transformOrigin: '0 0',
      backgroundColor: '#fff',
      textAlign: 'center',
      width: '4px',
      height: '12px',
      borderRadius: '2px',
    },

    playbackPositionMarker: {
      cursor: 'pointer',
      pointerEvents: 'auto',
      bottom: 0,
      boxSizing: 'border-box',
      margin: 'auto',
      position: 'absolute',
      right: 'auto',
      top: 0,
      border: 'solid #1fabe2 0.1875em',
      borderRadius: '50%',
      width: '1.25em',
      height: '1.25em',
      borderColor: orange.darkTangerine,
      backgroundColor: orange.darkTangerine,
    },

    currentTimeLabel: {
      display: 'none',
      position: 'absolute',
      top: -31,
      marginLeft: -24,
      borderRadius: 4,
      backgroundColor: '#1b2023',
      color: 'white',
      padding: 8,
    },
  });
});

type SeekBarProps = {
  onSeek: (targetPercentage: number) => void
  currentTime: number
  duration: number
  breaks?: number[]
};

const SeekBar: React.FC<SeekBarProps> = ({
  currentTime, duration, onSeek, breaks,
}) => {
  const classes = useStyles();

  const [canSyncUi, setCanSyncUi] = useState<boolean>(true);
  const [ready, setReady] = useState<boolean>(false);

  const seekBarContainer = useRef<HTMLDivElement>();
  const playbackPosition = useRef<HTMLDivElement>();
  const markersContainer = useRef<HTMLDivElement>();
  const playbackPositionMarker = useRef<HTMLDivElement>();
  const seekBarSeekPosition = useRef<HTMLDivElement>();
  const currentTimeLabel = useRef<HTMLDivElement>();
  const playbackPositionPercentage = useRef<number>();

  const sanitizeOffset = (offset: number) => {
    /**
     * Since we track mouse moves over the whole document, the target can be outside the seek range,
     * and we need to limit it to the [0, 1] range.
     */
    let _offset = offset;

    if (offset < 0) {
      _offset = 0;
    } else if (offset > 1) {
      _offset = 1;
    }

    return _offset;
  };

  /**
   * Determines the values to scale by percent
   * @remarks When the scale is exactly 1 or very near 1 (and the browser internally rounds it to 1), browsers seem to render
   * the elements differently and the height gets slightly off, leading to mismatching heights when e.g. the buffer
   * level bar has a width of 1 and the playback position bar has a width < 1. A jittering buffer level around 1
   * leads to an even worse flickering effect. Various changes in CSS styling and DOM hierarchy did not solve the issue so
   * the workaround is to avoid a scale of exactly 1.
   */
  const getScaleFromPercent = (percent: number) => {
    let scale = percent / 100;

    if (scale >= 0.99999 && scale <= 1.00001) {
      scale = 0.99999;
    }
    return scale;
  };

  /**
   * Sets the position of the seek position indicator.
   * @param percent a number between 0 and 100
   */
  const setSeekPosition = useCallback((percent: number) => {
    const scale = getScaleFromPercent(percent);

    seekBarSeekPosition.current.style['transform'] = `scaleX(${scale})`;
    seekBarSeekPosition.current.style['-ms-transform'] = `scaleX(${scale})`;
    seekBarSeekPosition.current.style['-webkit-transform'] = `scaleX(${scale})`;
  }, []);

  /**
   * Sets the position of the playback position indicator.
   * @param percent a number between 0 and 100
   */
  const setPlaybackPosition = useCallback((percent: number) => {
    const scale = getScaleFromPercent(percent);

    playbackPosition.current.style['transform'] = `scaleX(${scale})`;
    playbackPosition.current.style['-ms-transform'] = `scaleX(${scale})`;
    playbackPosition.current.style['-webkit-transform'] = `scaleX(${scale})`;
  }, []);

  /**
   * Sets the position of the playback marker position indicator.
   * @param percent a number between 0 and 100
   */
  const setPlaybackMarkerPosition = useCallback((percent: number) => {
    playbackPositionPercentage.current = percent;

    const clientWidth = seekBarContainer.current.getBoundingClientRect().width;
    const px = (((clientWidth) / 100) * percent) - 10;

    playbackPositionMarker.current.style['transform'] = `translateX(${px}px)`;
    playbackPositionMarker.current.style['-ms-transform'] = `translateX(${px}px)`;
    playbackPositionMarker.current.style['-webkit-transform'] = `translateX(${px}px)`;
  }, []);

  /**
   * Clear seekbar from existing markers and create new ones.
   */
  const setMarkersPosition = useCallback(() => {
    if (!breaks?.length) {
      return;
    }

    // Delete all existing markers
    document.querySelectorAll('span.seekbar-marker').forEach((marker) => {
      marker.remove();
    });

    breaks.forEach((breakStartTime) => {
      if (breakStartTime > 0) {
        const breakStartTimeRatio = breakStartTime / duration;
        const pixels = seekBarContainer.current.clientWidth * breakStartTimeRatio;

        const node = document.createElement('span');

        // Used for unit tests
        node.setAttribute('data-time', breakStartTime.toString());

        node.style['transform'] = `translateX(${pixels}px)`;
        node.className = classes.marker;
        node.classList.add('seekbar-marker');

        markersContainer.current.appendChild(node);
      }
    });
  }, [breaks, classes.marker, duration]);

  /**
   * Get the horizontal offset from the mouse event
   * @param ref mutable ref object
   * @param event mouse event
   */
  const getHorizontalOffset = useCallback((ref: React.MutableRefObject<HTMLDivElement>, event: MouseEvent): number => {
    const elementRect = ref.current.getBoundingClientRect();
    const htmlRect = document.body.parentElement.getBoundingClientRect();

    const elementOffsetPx = elementRect.left - htmlRect.left;
    return event.pageX - elementOffsetPx;
  }, []);

  /**
   * Returns position offset of the mouse event
   * @param event
   * @returns number
   */
  const getMouseEventPosition = useCallback((event: MouseEvent): number => {
    const offsetPx = getHorizontalOffset(seekBarContainer, event);
    const widthPx = seekBarContainer.current.getBoundingClientRect().width;
    const offset = (1 / widthPx) * offsetPx;
    return 100 * sanitizeOffset(offset);
  }, [getHorizontalOffset]);

  const syncUi = useCallback((targetPercentage: number, shouldSyncSeekPosition: boolean = false) => {
    if (shouldSyncSeekPosition) {
      setSeekPosition(targetPercentage);
    }
    setPlaybackPosition(targetPercentage);
    setPlaybackMarkerPosition(targetPercentage);
  }, [setPlaybackMarkerPosition, setPlaybackPosition, setSeekPosition]);

  /**
   * Update the UI as the mouse is moved
   * @param event mouse event
   */
  const mouseMoveHandler = useCallback((event: MouseEvent) => {
    event.preventDefault();

    // Should not update UI based on receiver app current playback position if the user is still dragging the seek cursor
    setCanSyncUi(false);

    syncUi(getMouseEventPosition(event), true);
  }, [getMouseEventPosition, syncUi]);

  const mouseUpHandler = useCallback((event: MouseEvent) => {
    event.preventDefault();

    /**
     * Remove handlers, seek operation is finished
     */
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    /**
     * Update the UI
     */
    const targetPercentage = getMouseEventPosition(event);
    syncUi(targetPercentage, true);

    /**
     * Props onSeek callback
     */
    onSeek(targetPercentage);

    setCanSyncUi(true);
  }, [getMouseEventPosition, mouseMoveHandler, onSeek, syncUi]);

  useEffect(() => {
    if (playbackPositionMarker.current && seekBarContainer.current) {
      const updateCurrentTimeLabel = (offset, positionPercent) => {
        currentTimeLabel.current.style.display = 'block';
        currentTimeLabel.current.style.left = `${offset}px`;
        const currentTimeSeconds = (duration * positionPercent) / 100;
        currentTimeLabel.current.innerText = Duration.fromObject({ seconds: currentTimeSeconds })
          .toFormat('hh:mm:ss')
          .replace(/^0(?:0:0?)?/, '');
      };

      seekBarContainer.current.addEventListener('mousedown', (e: MouseEvent) => {
        e.preventDefault();
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      });

      /**
       * Display seek target indicator when mouse hovers or finger slides over seekbar
       */
      seekBarContainer.current.addEventListener('mousemove', (event: MouseEvent) => {
        event.preventDefault();
        const position = getMouseEventPosition(event);
        setSeekPosition(position);
        updateCurrentTimeLabel(getHorizontalOffset(seekBarContainer, event), position);
      });

      /**
       * Hide seek target indicator when mouse leaves seekbar
       */
      seekBarContainer.current.addEventListener('mouseleave', (event: MouseEvent) => {
        event.preventDefault();
        setSeekPosition(0);
        currentTimeLabel.current.style.display = 'none';
      });
    }
  }, [duration, getHorizontalOffset, getMouseEventPosition, mouseMoveHandler, mouseUpHandler, setSeekPosition]);

  /**
   * Initialize UI
   */
  useEffect(() => {
    if (canSyncUi && seekBarContainer.current) {
      syncUi((100 / duration) * currentTime);
      setMarkersPosition();
    }
  }, [canSyncUi, currentTime, duration, setMarkersPosition, syncUi]);

  /**
   * Handle re-positioning the playback marker upon resizing the browser window
   */
  const refreshPlaybackPosition = useCallback(() => {
    setPlaybackMarkerPosition(playbackPositionPercentage.current);
  }, [setPlaybackMarkerPosition]);

  /**
   * Throttle window resize event listeners
   */
  useEffect(() => {
    const throttledRefreshPlaybackPosition = throttle(refreshPlaybackPosition, 300);
    const throttledRefreshMarkersPosition = throttle(setMarkersPosition, 300);

    window.addEventListener('resize', throttledRefreshPlaybackPosition);
    window.addEventListener('resize', throttledRefreshMarkersPosition);

    return () => {
      window.removeEventListener('resize', throttledRefreshPlaybackPosition);
      window.removeEventListener('resize', throttledRefreshMarkersPosition);
    };
  }, [refreshPlaybackPosition, setMarkersPosition]);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <div ref={seekBarContainer} className={clsx(classes.seekBarContainer, ready && 'ready')} data-testid="seekBarContainer">
      <div className={classes.backdrop}/>
      <div className={classes.bufferLevel}/>
      <div ref={playbackPosition} className={classes.playbackPosition} data-testid="playbackPosition"/>
      <div ref={seekBarSeekPosition} className={classes.seekPosition} data-testid="seekPosition"/>
      <div ref={markersContainer} className={classes.markersContainer} data-testid="markersContainer"/>
      <div ref={playbackPositionMarker} className={classes.playbackPositionMarker} data-testid="playbackPositionMarker"/>
      <div ref={currentTimeLabel} className={classes.currentTimeLabel} data-testid="currentTimeLabel">00:00</div>
    </div>
  );
};

export default SeekBar;
