import type { StreamHandlerConfig } from '@@src/lib/VideoPlayerV2/StreamHandlers/StreamHandler';
import type { OztamTrackingConfig } from '@@src/lib/VideoPlayerV2/plugins/OztamTracking/OztamTracking';

export const PlaybackEventTypes = [
  'recapBegin',
  'recapEnd',
  'offerSkipRecap',
  'openingCreditsBegin',
  'openingCreditsEnd',
  'offerSkipOpeningCredits',
  'closingCreditsBegin',
  'offerNextEpisode',
] as const;

type PlaybackEventType = typeof PlaybackEventTypes[number];

export type PlaybackEvents = Partial<Record<PlaybackEventType, number>>;

export const SupportedClassifications = ['G', 'PG', 'M', 'MA15+'] as const;

export type SupportedClassification = typeof SupportedClassifications[number];

export type PlaybackStreamData = {
  cdpTitle: string;
  title: string;
  subtitle?: string;
  reportingTitle: string;
  mpxId: string;
  streamHandlerConfigs: StreamHandlerConfig[];
  streamType: 'live' | 'vod';
  ogImageId?: string;
  oztam?: OztamTrackingConfig;
  playbackEvents: PlaybackEvents;
  classification: SupportedClassification | null;
  consumerAdviceTexts: string[];
  duration: number;
};
