import { makeStyles, createStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useState, useMemo } from 'react';
import { LiveAnnouncer } from 'react-aria-live';
import { ErrorBoundary } from 'react-error-boundary';
import Helmet from 'react-helmet';
import { Prompt, Route, Switch, useLocation } from 'react-router-dom';

import ChromeCastRemotePlayer from '@@src/components/ChromeCast/ChromeCastRemotePlayer';
import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { getIsReady, getUserEmailHash, getUserId } from '@@stores/UserStore';
import { hasFeature } from '@@utils/config';
import { getScrollPosition, saveLocationScrollPosition } from '@@utils/helpers';

import appleTouchIconIpadRetina from '../static/apple-touch-icons/touch-icon-ipad-retina.png';
import appleTouchIconIpad from '../static/apple-touch-icons/touch-icon-ipad.png';
import appleTouchIconIphoneRetina from '../static/apple-touch-icons/touch-icon-iphone-retina.png';
import appleTouchIconIphone from '../static/apple-touch-icons/touch-icon-iphone.png';
import favicon192 from '../static/favicons/favicon-192.png';
import favicon32 from '../static/favicons/favicon-32.png';
import favicon512 from '../static/favicons/favicon-512.png';
import favicon96 from '../static/favicons/favicon-96.png';
import faviconIco from '../static/favicons/favicon.ico';
import faviconSvg from '../static/favicons/favicon.svg';
import ProviderWrapper from './ProviderWrapper';
import { GenericError } from './components/Error/Error';
import InlineError from './components/Error/InlineError';
import LoginForm from './components/Login/LoginForm';
import MainMenu from './components/Menus/MainMenu';
import GeoblockBanner from './components/MessageBanner/GeoblockBanner';
import MessageBannerList, { BannerConfig, clearMessageBannerExpiredValues } from './components/MessageBanner/MessageBannerList';
import SwiftStreetSurveyBanner from './components/MessageBanner/SwiftStreetSurveyBanner';
import TvWeekLogiesBanner from './components/MessageBanner/TvWeekLogiesBanner';
import AdBlockerModal from './components/Modals/AdBlockerModal';
import Footer from './components/PageLayouts/Footer';
import FavouritesSnackbar from './components/Snackbars/FavouritesSnackbar';
import useDetectAdBlocker from './hooks/useDetectAdBlocker';
import { SupportedLanguage } from './i18n';
import withSSR from './pages/withSSR';
import { routes } from './routes';
import { switchOn, switchOff } from './stores/SettingsStore';
import DataLayer from './utils/DataLayer';
import { BASENAME, BUILD_HASH, VERSION } from './utils/constants';
import Logger from './utils/logger/Logger';

function useOnLocationChange() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const loginStatusIsReady = useAppSelector(getIsReady);
  const userId = useAppSelector(getUserId);
  const emailHash = useAppSelector(getUserEmailHash);

  useEffect(() => {
    if (loginStatusIsReady) {
      DataLayer.updateUserData(userId, emailHash);
    }
  }, [userId, emailHash, loginStatusIsReady]);

  const [serverSideRenderLocation, setServerSideRenderLocation] = useState(location.pathname);
  // set a redux state whether the page was server side rendered or client side rendered
  useEffect(() => {
    if (serverSideRenderLocation === location.pathname) {
      dispatch(switchOn('serverSideRendered'));
    } else {
      dispatch(switchOff('serverSideRendered'));
      setServerSideRenderLocation(null);
    }
  }, [dispatch, location.pathname, serverSideRenderLocation]);

  // when user is exiting the app, we'll try to save the current location so that we can restore it if they decide to come back
  useEffect(() => {
    const _saveLocationScrollPosition = () => {
      saveLocationScrollPosition(location, getScrollPosition());
    };

    window.addEventListener('beforeunload', _saveLocationScrollPosition);

    return () => {
      window.removeEventListener('beforeunload', _saveLocationScrollPosition);
    };
  }, [location]);
}

const useStyles = makeStyles(() => {
  return createStyles({
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    mainContent: {
      paddingTop: 60,
      flex: 1,
    },
    messageBanner: {
      position: 'fixed',
      zIndex: 100,
    },
  });
});

// this ContentWrapper is created to avoid re-render of InnerApp when useSelector returns a different value (eg: player open state is changed)
const ContentWrapper = ({ language, children }) => {
  const classes = useStyles({});

  useEffect(() => {
    DataLayer.updatePageLanguage(language);

    // clear message banner expired values
    clearMessageBannerExpiredValues();
  }, [language]);

  // if the document.title gets updated by the child component, we should call DataLayer.updatePageData
  // so that it will update the property that reads from document.title
  const handleChangeClientState = useCallback(() => {
    DataLayer.partialUpdatePageData();
  }, []);

  useOnLocationChange();
  const adBlockerDetected = useDetectAdBlocker();

  // adBlockerWatchLink will open the ad blocker modal if set
  const [adBlockerWatchLink, setAdBlockerWatchLink] = useState<string>(null);

  const handleAdBlockerPrompt = useCallback((newLocation) => {
    // when going to watch page and watch link hasn't been set, set the watch link to open the ad blocker modal
    if (newLocation.pathname.startsWith('/watch') && !adBlockerWatchLink) {
      // the _location doesn't have the basename because it's generated by react route,
      // we're adding the basename as the AdBlockerModal is expecting this to be a normal link instead of an SPA link
      setAdBlockerWatchLink(`${BASENAME}${newLocation.pathname}`);
      return false;
    }

    // when going to other pages, reset the path so it would close the ad blocker modal
    setAdBlockerWatchLink('');
    return true;
  }, [adBlockerWatchLink, setAdBlockerWatchLink]);

  // when the ad blocker is closed, set watch link to null to close the modal
  const handleCloseAdBlockerModal = useCallback(() => {
    setAdBlockerWatchLink(null);
  }, [setAdBlockerWatchLink]);

  return (
    <>
      <Helmet
        onChangeClientState={handleChangeClientState}
        defaultTitle="SBS On Demand"
        titleTemplate="%s | SBS On Demand"
      >
        <html lang={language}/>
        <link rel="icon" type="image/svg+xml" href={faviconSvg}/>
        <link rel="icon" type="image/png" href={favicon32} sizes="32x32"/>
        <link rel="icon" type="image/png" href={favicon96} sizes="96x96"/>
        <link rel="icon" type="image/png" href={favicon192} sizes="192x192"/>
        <link rel="icon" type="image/png" href={favicon512} sizes="512x512"/>
        <link rel="shortcut icon" href={faviconIco} sizes="16x16"/>
        <link rel="apple-touch-icon" href={appleTouchIconIphone}/>
        <link rel="apple-touch-icon" sizes="152x152" href={appleTouchIconIpad}/>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIconIphoneRetina}/>
        <link rel="apple-touch-icon" sizes="167x167" href={appleTouchIconIpadRetina}/>
      </Helmet>
      <div style={{ overflow: 'clip' }} className={classes.content}>
        {children}
      </div>
      <Prompt when={adBlockerDetected === true} message={handleAdBlockerPrompt}/>
      <AdBlockerModal open={!!adBlockerWatchLink} watchLink={adBlockerWatchLink} onClose={handleCloseAdBlockerModal}/>
    </>
  );
};

const logUncaughtError = (error: Error, componentStack: string) => {
  Logger.error('Uncaught error', {
    componentStack,
    error: {
      stack: error.stack,
      message: error.message,
    },
  });

  DataLayer.events.pageLoad('', '500', error.message);
};

interface AppProps {
  store: any;
  initialProps: any;
  language: SupportedLanguage;
  ssr?: boolean;
}

// Created InnerApp for testability
export const InnerApp: FunctionComponent<AppProps> = (props) => {
  const {
    store, initialProps, language, ssr,
  } = props;

  const classes = useStyles(props);

  const pageLoadSuccessHandler = useCallback((title: string) => {
    if (typeof window !== 'undefined') {
      DataLayer.updatePageData();
      DataLayer.events.pageLoad(title);
    }
  }, []);

  const pageLoadErrorHandler = useCallback((errorCode: string, errorMessage: string) => {
    if (typeof window !== 'undefined') {
      DataLayer.updatePageData();
      DataLayer.events.pageLoad('', errorCode, errorMessage);
    }
  }, []);

  const bannerConfigs = useMemo(() => {
    const configs: BannerConfig[] = [
      {
        name: 'Geoblock banner',
        component: <GeoblockBanner classes={{ root: classes.messageBanner }}/>,
        displayRules: [{ geoblocked: true }],
      },
    ];

    if (hasFeature('surveyBanner')) {
      configs.push({
        name: 'Survey banner',
        component: <SwiftStreetSurveyBanner classes={{ root: classes.messageBanner }}/>,
        displayRules: [{ pathname: '/tv-series/swift-street' }],
      });
    }

    configs.push({
      name: 'Logies banners',
      component: <TvWeekLogiesBanner classes={{ root: classes.messageBanner }}/>,

      // Just like for sponsorship, in the future having the CMS handles message banners would make more sense?
      displayRules: [
        { pathname: '/', endDate: '2024-08-05T23:59:59+10:00' },
        { pathname: '/tv-series/alone-australia', endDate: '2024-08-17T23:59:59+10:00' },
        { pathname: '/tv-series/safe-home', endDate: '2024-08-17T23:59:59+10:00' },
        { pathname: '/tv-series/erotic-stories', endDate: '2024-08-17T23:59:59+10:00' },
        { pathname: '/tv-series/eddies-lil-homies', endDate: '2024-08-17T23:59:59+10:00' },
      ],
    });

    return configs;
  }, [classes.messageBanner]);

  return (
    <>
      <Route
        render={({ location }) => {
          return (
            <>
              {!location.pathname.startsWith('/watch') && (
                <>
                  <div className="branch-journeys-top"/>
                  <MainMenu/>
                </>
              )}
            </>
          );
        }}
      />
      <ContentWrapper language={language}>
        <>
          <Switch>
            {
              Object.keys(routes).map((routeName) => {
                const route = routes[routeName];

                let Page = null;

                if (route.disabled) {
                  return null;
                }

                const disableSSR = route.disableSSR === true;

                // don't render Page if it's SSR and SSR is disabled
                if (!(ssr && disableSSR)) {
                  // Page is better to be created outside of the <Route> component so that it
                  // won't get re-created on every route change, however it needs to check if url has changed to fetch new data for the page
                  Page = withSSR(route.component, pageLoadSuccessHandler, pageLoadErrorHandler);
                }

                return (
                  <Route
                    key={routeName}
                    path={route.path}
                    exact={route.exact}
                    render={
                      (componentProps) => {
                        const { pathname } = componentProps.location;

                        // the server caught an error, render a generic error
                        if (get(initialProps, `${pathname}.error`, false)) {
                          pageLoadErrorHandler('500', 'Unknown Error');
                          return <GenericError/>;
                        }

                        return (
                          /* This ErrorBoundary is to catch non fatal error so that we can render an error message with a nice layout */
                          <ErrorBoundary
                            FallbackComponent={GenericError}
                            onError={logUncaughtError}
                          >
                            <main
                              className={classes.mainContent}
                              data-testid="main-content"
                              id="main"
                            >
                              {
                                // add noindex meta tag if SSR is disabled
                                disableSSR && (
                                  <Helmet>
                                    <meta name="robots" content="noindex"/>
                                  </Helmet>
                                )
                              }
                              {
                                Page && (
                                  <Page
                                    key={route.path}
                                    store={store}
                                    initialProps={(initialProps?.[pathname]) || null}
                                    ssr={ssr}
                                    language={language}
                                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                                    {...componentProps}
                                  />
                                )
                              }
                            </main>
                          </ErrorBoundary>
                        );
                      }
                    }
                  />
                );
              })
            }
          </Switch>
          <Switch>
            <Route
              render={({ location }) => {
                return (
                  <>
                    {!location.pathname.startsWith('/watch') && (
                      <>
                        {!ssr && (
                          <>
                            <MessageBannerList bannerConfigs={bannerConfigs}/>
                            <ChromeCastRemotePlayer/>
                          </>
                        )}
                        <Footer/>
                      </>
                    )}
                  </>
                );
              }}
            />
          </Switch>
        </>
      </ContentWrapper>
      <LoginForm/>
      <FavouritesSnackbar/>
    </>
  );
};

const App: FunctionComponent<AppProps> = (props) => {
  const { store, language } = props;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    // eslint-disable-next-line no-console
    console.info(`VERSION: ${VERSION}-${BUILD_HASH}`);
  });

  const renderFatalError = () => {
    let basepath = BASENAME;
    if (language !== 'en') {
      basepath = `${basepath}/${language}`;
    }

    if (window.location.pathname !== `${basepath}/error`) {
      window.location.href = `${basepath}/error`;
    }

    return <InlineError homeLink={basepath}/>;
  };

  return (
    /* This ErrorBoundary is to catch FATAL error, we can't render the error message with a nice layout */
    <ErrorBoundary
      fallbackRender={renderFatalError}
      onError={logUncaughtError}
    >
      <ProviderWrapper store={store}>
        <LiveAnnouncer>
          <InnerApp {...props}/>
        </LiveAnnouncer>
      </ProviderWrapper>
    </ErrorBoundary>
  );
};

export default App;
