import { ClassNameMap } from '@material-ui/styles';
import { RefObject } from 'react';

import OnDemand2 from '@@src/@types/OnDemand2';

import CollectionTile from './CollectionTile';
import PageTile from './PageTile';
import SeriesTile from './SeriesTile';
import VideoTile from './VideoTile';

interface TileProps {
  ref?: RefObject<HTMLDivElement>;
  className: string;
  onActivate: () => void;
  onDeactivate: () => void;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent) => void;
  canBeFocused?: boolean;
  classes?: ClassNameMap;
}

const getTileBasedOnEntityType = (displayType: '16:9' | '2:3', tileItem: OnDemand2.CollectionItem, tileProps: TileProps) => {
  const { entityType } = tileItem;

  switch (entityType) {
    case 'Movie':
    case 'Program':
    case 'Episode':
    case 'Clip':
      return (
        <VideoTile
          collectionTileItem={tileItem}
          displayType={displayType}
          {...tileProps}
        />
      );
    case 'Series':
      return (
        <SeriesTile
          collectionTileItem={tileItem}
          displayType={displayType}
          {...tileProps}
        />
      );
    case 'Collection':
      return (
        <CollectionTile
          collectionTileItem={tileItem}
          displayType={displayType}
          {...tileProps}
        />
      );
    case 'Page':
      return (
        <PageTile
          collectionTileItem={tileItem}
          displayType={displayType}
          {...tileProps}
        />
      );
    default:
      return null;
  }
};

export default getTileBasedOnEntityType;
