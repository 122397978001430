import { get } from 'lodash';
import { Duration } from 'luxon';

import OnDemand from '../@types/OnDemand';
import { getResizedUrl } from '../components/Html/Image';
import { generateFullUrlFromLinkProps } from '../routes';

const generateEpisodeSchema = (
  episode: OnDemand.SlimEpisode,
  pageLanguage: string = 'en',
  isTopLevel: boolean = true,
) => {
  if (!episode) {
    return '';
  }

  const contentLanguage = get(episode, 'languages[0]', 'en');
  const imagehd720Url = get(episode, 'images.hd720');
  const imageDefault = get(episode, 'images.default');
  let thumbnailUrl = imagehd720Url || imageDefault;
  if (episode?.odImageId) {
    thumbnailUrl = getResizedUrl({ imageId: episode.odImageId }, { width: 640, height: 360 });
  }
  const duration = get(episode, 'duration');

  const schema = {
    ...(isTopLevel && {
      '@context': 'https://schema.org/',
      '@id': generateFullUrlFromLinkProps(episode.route, pageLanguage),
    }),
    '@type': 'TVEpisode',
    url: generateFullUrlFromLinkProps(episode.route, pageLanguage),
    name: episode.title,
    image: thumbnailUrl,
    description: episode.description,
    episodeNumber: episode.episodeData.episodeNumber,
    contentRating: episode.classification,
    ...(duration && {
      timeRequired: Duration.fromObject({ hours: 0, minutes: 0, seconds: duration }).normalize().toISO(),
    }),
    ...(isTopLevel && {
      inLanguage: contentLanguage,
      partOfSeason: {
        '@type': 'TVSeason',
        '@id': `${generateFullUrlFromLinkProps(episode.episodeData.seriesRoute, pageLanguage)}/season-${episode.episodeData.seasonNumber}`,
        seasonNumber: episode.episodeData.seasonNumber,
      },
      partOfSeries: {
        '@type': 'TVSeries',
        '@id': generateFullUrlFromLinkProps(episode.episodeData.seriesRoute, pageLanguage),
        name: episode.episodeData.programName,
      },
      potentialAction: {
        '@type': 'ViewAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: generateFullUrlFromLinkProps(episode.route, pageLanguage),
          actionPlatform: [
            'https://schema.org/DesktopWebPlatform',
            'https://schema.org/MobileWebPlatform',
            'https://schema.org/AndroidPlatform',
            'https://schema.org/IOSPlatform',
          ],
        },
        actionAccessibilityRequirement: {
          '@type': 'ActionAccessSpecification',
          category: 'free',
          name: 'SBS On Demand',
          eligibleRegion: {
            '@type': 'Country',
            name: 'AU',
          },
          availabilityStarts: episode.availableDate,
          availabilityEnds: episode.expiredDate,
        },
      },
    }),
  };

  return schema;
};

const generateLiveStreamSchema = (
  video,
  pageLanguage: string = 'en',
) => {
  if (!video) {
    return '';
  }

  const imagehd720Url = get(video, 'images.hd720');
  const imageDefault = get(video, 'images.default');
  let thumbnailUrl = imagehd720Url || imageDefault;
  if ('odImageId' in video && video.odImageId) {
    thumbnailUrl = getResizedUrl({ imageId: video.odImageId }, { width: 640, height: 360 });
  }

  const duration = get(video, 'duration', 0);
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'VideoObject',
    '@id': generateFullUrlFromLinkProps(video.route, pageLanguage),
    url: generateFullUrlFromLinkProps(video.route, pageLanguage),
    name: video.title,
    thumbnailUrl,
    embedUrl: generateFullUrlFromLinkProps(video.route, pageLanguage),
    description: video.description,
    uploadDate: video.availableDate,
    ...(duration && {
      duration: Duration.fromObject({ hours: 0, minutes: 0, seconds: duration }).normalize().toISO(),
    }),
    publication: {
      '@type': 'BroadcastEvent',
      isLiveBroadcast: true,
      startDate: video.availableDate,
      endDate: video.expiredDate,
    },
  };

  return schema;
};

const generateOtherVideoSchema = (
  video: OnDemand.Video,
  pageLanguage: string = 'en',
) => {
  if (!video) {
    return '';
  }

  let schemaType = get(video, 'type', 'Movie');
  if (schemaType === 'OneOff') {
    schemaType = 'Movie';
  }

  const contentLanguage = get(video, 'languages[0]', 'en');
  const publicationYear = get(video, 'publicationYear');
  const countries = get(video, 'countries');
  const imagehd720Url = get(video, 'images.hd720');
  const imageDefault = get(video, 'images.default');
  let imageUrl = imagehd720Url || imageDefault;
  if ('odImageId' in video && video.odImageId) {
    imageUrl = getResizedUrl({ imageId: video.odImageId }, { width: 640, height: 360 });
  }
  const schema = {
    '@context': 'https://schema.org/',
    '@type': schemaType,
    '@id': generateFullUrlFromLinkProps(video.route, pageLanguage),
    url: generateFullUrlFromLinkProps(video.route, pageLanguage),
    name: video.title,
    image: {
      '@type': 'ImageObject',
      url: imageUrl,
    },
    dateCreated: video.availableDate,
    ...(video.directors && video.directors.length > 0 && {
      director: video.directors.map((actor) => {
        return {
          '@type': 'Person',
          name: actor,
        };
      }),
    }),
    ...(video.cast && video.cast.length > 0 && {
      actor: video.cast.map((actor) => {
        return {
          '@type': 'Person',
          name: actor,
        };
      }),
    }),
    description: video.description,
    contentRating: video.classification,

    genre: video.genres,

    inLanguage: {
      '@type': 'Language',
      name: contentLanguage,
    },

    ...((publicationYear || countries) && {
      releasedEvent: {
        '@type': 'PublicationEvent',
        ...(publicationYear && {
          startDate: publicationYear,
        }),
        ...(countries && {
          location: {
            '@type': 'Country',
            name: countries,
          },
        }),
      },
    }),
    potentialAction: {
      '@type': 'ViewAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: generateFullUrlFromLinkProps(video.route, pageLanguage),
        actionPlatform: [
          'https://schema.org/DesktopWebPlatform',
          'https://schema.org/MobileWebPlatform',
          'https://schema.org/AndroidPlatform',
          'https://schema.org/IOSPlatform',
        ],
      },
      actionAccessibilityRequirement: {
        '@type': 'ActionAccessSpecification',
        category: 'free',
        name: 'SBS On Demand',
        eligibleRegion: {
          '@type': 'Country',
          name: 'AU',
        },
        availabilityStarts: video.availableDate,
        availabilityEnds: video.expiredDate,
      },
    },
  };

  return schema;
};

export const generateTvSeasonSchema = (series: OnDemand.TvSeries, pageLanguage: string = 'en', seasonSlug?: string) => {
  const contentLanguage = get(series, 'languages[0]', 'en');

  return series.seasons
    .filter((season) => {
      return !seasonSlug || season.seasonSlug === seasonSlug;
    })
    .map((seasonData) => {
      if (seasonData.name !== 'Extras') {
        return {
          '@type': 'TVSeason',
          url: generateFullUrlFromLinkProps(series.route, pageLanguage),
          name: seasonData.name,
          seasonNumber: seasonData.seasonNumber,
          numberOfEpisodes: seasonData.numberOfEpisodesAvailable,
          episode: seasonData.episodes.map((episode) => {
            return generateEpisodeSchema(episode, pageLanguage, false);
          }),
          inLanguage: contentLanguage,
          partOfSeries: {
            '@type': 'TVSeries',
            '@id': generateFullUrlFromLinkProps(series.route, pageLanguage),
            name: series.title,
          },
          potentialAction: {
            '@type': 'ViewAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: generateFullUrlFromLinkProps(series.route, pageLanguage),
              actionPlatform: [
                'https://schema.org/DesktopWebPlatform',
                'https://schema.org/MobileWebPlatform',
                'https://schema.org/AndroidPlatform',
                'https://schema.org/IOSPlatform',
              ],
            },
            actionAccessibilityRequirement: {
              '@type': 'ActionAccessSpecification',
              category: 'free',
              name: 'SBS On Demand',
              eligibleRegion: {
                '@type': 'Country',
                name: 'AU',
              },
              availabilityStarts: seasonData.availabilityStarts,
              availabilityEnds: seasonData.availabilityEnds,
            },
          },
        };
      }

      return null;
    }).filter((season) => {
      return season !== null;
    });
};

export const generateTvSeriesSchema = (
  series: OnDemand.TvSeries,
  pageLanguage: string = 'en',
) => {
  if (!series) {
    return '';
  }

  let thumbnailUrl = get(series, 'images.169CoverArt');
  if (series?.odImageId) {
    thumbnailUrl = getResizedUrl({ imageId: series.odImageId }, { width: 640, height: 360 });
  }

  const contentLanguage = get(series, 'languages', ['English']);

  const countries = get(series, 'countries');
  const validSeasons = generateTvSeasonSchema(series);
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'TVSeries',
    '@id': generateFullUrlFromLinkProps(series.route, pageLanguage),
    name: series.title,
    url: generateFullUrlFromLinkProps(series.route, pageLanguage),
    description: series.description,
    contentRating: series.classification,
    genre: series.genres,
    numberOfSeasons: validSeasons.length,
    inLanguage: contentLanguage,
    image: thumbnailUrl,
    releasedEvent: {
      '@type': 'PublicationEvent',
      name: series.title,
      ...(countries && {
        location: {
          '@type': 'Country',
          name: countries,
        },
      }),
      startDate: get(series, 'availability.availabilityStarts'),
      endDate: get(series, 'availability.availabilityEnds'),
    },
    potentialAction: {
      '@type': 'ViewAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: generateFullUrlFromLinkProps(series.route, pageLanguage),
        actionPlatform: [
          'https://schema.org/DesktopWebPlatform',
          'https://schema.org/MobileWebPlatform',
          'https://schema.org/AndroidPlatform',
          'https://schema.org/IOSPlatform',
        ],
      },
      actionAccessibilityRequirement: {
        '@type': 'ActionAccessSpecification',
        category: 'free',
        name: 'SBS On Demand',
        eligibleRegion: {
          '@type': 'Country',
          name: 'AU',
        },
        availabilityStarts: get(series, 'availability.availabilityStarts'),
        availabilityEnds: get(series, 'availability.availabilityEnds'),
      },
    },
    ...(series.cast.length > 0 && {
      actor: series.cast.map((actor) => {
        return {
          '@type': 'Person',
          name: actor,
        };
      }),
    }),
    containsSeason: validSeasons,
  };

  return schema;
};

export const generateVideoSchema = (video, pageLanguage: string = 'en') => {
  if (!video) {
    return '';
  }

  const type = get(video, 'type', null);

  if (video.isLiveStream) {
    return generateLiveStreamSchema(video, pageLanguage);
  }

  if (type === 'Episode') {
    return generateEpisodeSchema(video, pageLanguage);
  }

  if (type) {
    return generateOtherVideoSchema(video, pageLanguage);
  }

  return null;
};

export const generateLiveTvSchema = (epgGuides, pageLanguage: string = 'en') => {
  const structuredData = {
    '@type': 'ItemList',
    itemListElement: [],
  };

  for (let gi = 0; gi < epgGuides.length; gi += 1) {
    const epgGuide = epgGuides[gi];
    const { channel } = epgGuide;
    const { mpxMediaID, title, imageUrl } = channel;

    const url = generateFullUrlFromLinkProps({ name: 'video', params: { id: mpxMediaID } }, pageLanguage);
    const channelStructuredData = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      '@id': url,
      thumbnailUrl: imageUrl,
      contentURL: url,
      description: `${title} Live Stream`,
      name: title,
      uploadDate: '',
      position: gi + 1,
      publication: [],
    };

    for (let pi = 0; pi < epgGuide.programs.length; pi += 1) {
      const program = epgGuide.programs[pi];
      const {
        title: programTitle,
        description: programDescription,
        start,
        end,
      } = program;

      if (pi === 0) {
        channelStructuredData.uploadDate = start;
      }

      channelStructuredData.publication.push({
        '@type': 'BroadcastEvent',
        isLiveBroadcast: true,
        name: programTitle,
        description: programDescription,
        startDate: start,
        endDate: end,
      });
    }

    structuredData.itemListElement.push(channelStructuredData);
  }

  return structuredData;
};
