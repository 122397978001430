import { AxiosResponse } from 'axios';

import PlaybackApiTransformer from '@@src/transformers/PlaybackApiTransformer';
import { paths } from '@@types/PlaybackApi';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import { logHttpError, playbackHttpClient } from '@@utils/HttpClient';
import { PLAYBACK_STREAM_BY_MPX_ID } from '@@utils/constants';

export class PlaybackStreamNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PlaybackStreamNotFoundError';
  }
}

export class PlaybackStreamUnauthorisedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PlaybackStreamUnauthorisedError';
  }
}

export type PlaybackStreamOptions = {
  telariaId?: string;
  ozTamSessionId?: string;
  subtitle?: string;
};

const getPlaybackStreamPromises: Record<string, Promise<PlaybackStreamData>> = {};

function getPlaybackStream(mpxId: string, language: string, options?: PlaybackStreamOptions) {
  const path = `${PLAYBACK_STREAM_BY_MPX_ID}` as const;
  type ReturnType = paths[typeof path]['post']['responses']['200']['content']['application/json'];
  type DataType = paths[typeof path]['post']['requestBody']['content']['application/json'];

  const httpClient = playbackHttpClient(language);
  const cacheKey = `${mpxId}${language}`;

  if (!(cacheKey in getPlaybackStreamPromises) || !(getPlaybackStreamPromises[cacheKey] instanceof Promise)) {
    getPlaybackStreamPromises[cacheKey] = httpClient.post<ReturnType, AxiosResponse<ReturnType>, DataType>(
      PLAYBACK_STREAM_BY_MPX_ID.replace('{id}', mpxId),
      {
        deviceClass: 'web',
        advertising: {
          headerBidding: true,
          telariaID: options?.telariaId || null,
          ozTamSessionID: options?.ozTamSessionId || null,
        },
        streamOptions: {
          audio: 'demuxed',
        },
        streamProviders: ['GoogleDAI', 'HLS'],
        ...(options?.subtitle && { subtitle: options.subtitle }),
      },
    ).then(({ data }) => {
      getPlaybackStreamPromises[cacheKey] = undefined;
      return PlaybackApiTransformer.transform(data);
    }).catch((e) => {
      getPlaybackStreamPromises[cacheKey] = undefined;
      if (e.response.status === 404) {
        const error = new PlaybackStreamNotFoundError('Video is not available');
        logHttpError(e, error, 'warn');
        throw error;
      }

      if (e.response.status === 401) {
        const error = new PlaybackStreamUnauthorisedError('Unauthorised');
        logHttpError(e, error, 'warn');
        throw error;
      }

      const error = new Error('Unhandled PlaybackAPI error');
      logHttpError(e, error, 'error');
      throw error;
    });
  }

  return getPlaybackStreamPromises[cacheKey];
}

export default getPlaybackStream;
