import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Heart from '@@icons/Heart';
import OnDemand2 from '@@src/@types/OnDemand2';
import { useFavouriteButtonStyles } from '@@src/components/Buttons/OdFavouriteButton';
import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { addContentToFavourites, removeContentFromFavourites, isInFavourite, FavouritedContent } from '@@stores/FavouritesStoreAsyncActions';
import { getIsLoggedIn, getIsReady } from '@@stores/UserStore';
import DataLayer from '@@utils/DataLayer';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as FavouriteIcon } from '../../images/icons/favourite.svg';
import Button from '../Inputs/Button';
import { dispatchOpenSignIn } from '../Login/LoginForm';

interface OdFavouriteButtonProps {
  item: OnDemand2.CollectionItem;
  buttonType?: 'favourite' | 'close';
  showLabel?: boolean;
  tabIndex?: number;
  classes?: any;
  children?: React.ReactNode;
  onClick?(e: MouseEvent, favourited: boolean, item: OnDemand2.CollectionItem): void;
  location?: string;
  autoAdd?: boolean;
}

const OdFavouriteButton: FunctionComponent<OdFavouriteButtonProps> = (props) => {
  const {
    item,
    tabIndex = 0,
    showLabel = false,
    onClick,
    buttonType = 'favourite',
    location: buttonLocation = '',
    autoAdd = false,
  } = props;
  const classes = useFavouriteButtonStyles(props);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  const loggedIn = useAppSelector(getIsLoggedIn);
  const loginStatusIsReady = useAppSelector(getIsReady);

  const inFavourite = useAppSelector((state) => {
    return isInFavourite(state, item);
  });

  let label;
  if (inFavourite) {
    label = t('button.removeFromFavourites');
  } else {
    label = t('button.addToFavourites');
  }

  const toggleFavourite = useCallback((forcedAdd: boolean = false) => {
    let action;
    let hasFavourited = false;

    const generateItemToRemove = (_item: OnDemand2.CollectionItem, _buttonType: 'favourite' | 'close') => {
      let itemToRemove: FavouritedContent = {
        id: item.id,
        ...('mpxMediaId' in item && { mpxMediaId: item.mpxMediaId }),
        entityType: item.entityType,
      };

      // Removing an episode from the FavouritePage will actually remove the episode.
      // From other locations, it will remove the related tv series.
      if (_buttonType !== 'close') {
        if (_item.entityType === 'Episode') {
          itemToRemove = {
            entityType: 'Series',
            id: get(_item, 'seriesId'),
          };
        } else if (get(_item, 'type') === 'Episode') {
          itemToRemove = {
            entityType: 'Series',
            id: get(_item, 'episodeData.seriesId'),
          };
        }
      }

      return itemToRemove;
    };

    if (!inFavourite) {
      if (buttonLocation) {
        DataLayer.events.addToFavouritesV2(item, buttonLocation);
      }
      action = addContentToFavourites(item as FavouritedContent);
      hasFavourited = true;
    } else if (!forcedAdd) {
      const itemToRemove = generateItemToRemove(item, buttonType);

      if (buttonLocation) {
        DataLayer.events.removeFromFavouritesV2(item, buttonLocation);
      }

      action = removeContentFromFavourites(itemToRemove);
    }

    if (action) {
      if (!loggedIn) {
        dispatchOpenSignIn(action);
      } else {
        dispatch(action);
      }
    }

    return hasFavourited;
  }, [buttonLocation, buttonType, dispatch, inFavourite, item, loggedIn]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const hasFavourited = toggleFavourite();

    if (onClick) {
      onClick(e, hasFavourited, item);
    }
  }, [item, onClick, toggleFavourite]);

  useEffect(() => {
    if (loginStatusIsReady && autoAdd) {
      toggleFavourite(true);
    }
  }, [autoAdd, loginStatusIsReady, toggleFavourite]);

  const buttonProps = {
    title: label,
    'aria-label': label,
    onClick: handleClick,
    tabIndex,
  };

  if (showLabel) {
    return (
      <Button
        alternateColor={inFavourite}
        buttonType="text"
        size="large"
        startIcon={<Heart/>}
        {...buttonProps}
      >
        {label}
      </Button>
    );
  }

  const Icon = buttonType === 'favourite' ? FavouriteIcon : CloseIcon;

  return (
    <IconButton
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...buttonProps}
      classes={{
        root: clsx(classes.root, inFavourite ? 'in-favourite' : ''),
      }}
    >
      <Icon/>
    </IconButton>
  );
};

export default OdFavouriteButton;
